import gsap from 'gsap';

type Target = HTMLElement | (HTMLElement & { visibility?: number });
export function createElementFadeInOutControl(element: Target) {
  const propKey = 'visibility' in element ? 'visibility' : 'opacity';

  return {
    fadeIn(duration: number) {
      return gsap.fromTo(element, { [propKey]: 0 }, { [propKey]: 1, duration });
    },
    fadeOut(duration: number) {
      return gsap.fromTo(element, { [propKey]: 1 }, { [propKey]: 0, duration });
    },
    fadeOutAndRemove(duration: number) {
      return this.fadeOut(duration).then(() => element.remove());
    },
  };
}
