import { checkUserSignedInNotAnonymously } from '@/backend/ensureUserSignedIn';
import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';
import { getIsUserOnAnySubscriptionPlan } from '@/helpers/getIsUserOnAnySubscriptionPlan';
import { CompletionScreen } from '@/html/components/CompletionScreen';
import { IntroVideo } from '@/html/components/IntroVideo';
import { LandingSplashScreen } from '@/html/components/LandingSplashScreen';
import { WelcomeSplashScreen } from '@/html/components/WelcomeSplashScreen';
import { displayAlreadySignedInScreen } from '@/html/displayAlreadySignedInScreen';
import { displayGetNextPresenceShiftSplash } from '@/html/displayGetNextPresenceShiftSplash';
import { displayLearnMoreSplash_EndVersion } from '@/html/displayLearnMoreSplash';
import { displayShareSplash } from '@/html/displayShareSplash';
import { displaySigningSplash } from '@/html/displaySigningSplash';
import { displaySubscriptionScreen } from '@/html/displaySubscriptionScreen';
import { defaultVars } from '@/misc/defaultVars';
import { runPlayer } from '@/player';
import { ConversionReporting } from '@/tools/gtag/gtagReportConversion';
import { delay } from '@/util/core/delay';
import { getShifterName } from '@/util/getShifterName';
import { purgeAnyBoomerangVideos } from '../purgeAnyBoomerangVideos';
import { getOneSpecificCinematicSlugToPlay } from '../utils/getOneSpecificCinematicSlug';
import { FlowResolver } from './FlowResolver.type';

export function createCommonFlowResolvers() {
  const { appDiv, userData, events, urlParams, splashScreens } = appContext;

  const flowResolvers = {
    display_sign_in_or_aready_signed_in_page: async () => {
      const isLoggedIn = await checkUserSignedInNotAnonymously();
      if (!isLoggedIn) {
        await displaySigningSplash(splashScreens.container);
      } else {
        await displayAlreadySignedInScreen(splashScreens.container);
      }
    },
    ensure_user_signed_in: async () => {
      const isLoggedIn = await checkUserSignedInNotAnonymously();
      if (isLoggedIn) return;

      await displaySigningSplash(splashScreens.container);
    },
    play_intro_cinematic: async ({ loadSceneContext }) => {
      const sceneContext = await loadSceneContext();

      const introSlug = buildVars.cinematics.intro;
      if (!introSlug) throw new Error('No intro slug found');

      splashScreens.setSplashScreensBackgroundEnabled(false);
      await runPlayer(introSlug, sceneContext);
      splashScreens.setSplashScreensBackgroundEnabled(true);
    },
    play_ps1_cinematic: async ({ loadSceneContext }) => {
      const sceneContext = await loadSceneContext();

      const ps1Slug = buildVars.cinematics.ps1;
      if (!ps1Slug) throw new Error('No PS1 slug found');

      splashScreens.setSplashScreensBackgroundEnabled(false);
      await runPlayer(ps1Slug, sceneContext);
      splashScreens.setSplashScreensBackgroundEnabled(true);
    },
    play_cinematic_specified_in_url: async ({ loadSceneContext }) => {
      const sceneContext = await loadSceneContext();

      const cinematicScriptSlugToPlay = getOneSpecificCinematicSlugToPlay();
      if (!cinematicScriptSlugToPlay) throw new Error('No slug found in url to play');

      splashScreens.setSplashScreensBackgroundEnabled(false);
      await runPlayer(cinematicScriptSlugToPlay, sceneContext);
      splashScreens.setSplashScreensBackgroundEnabled(true);
    },
    play_intro_video: async () => {
      if (urlParams.skipSplash) return;

      ConversionReporting.reportConversion('pass_begin_screen');
      events.userConversionCheckpoint.emit('pass_begin_screen');

      const introVid = new IntroVideo();
      appDiv.appendChild(introVid);
      // errorCleanUpFunctions.push(() => introVid.remove());
      await introVid.updateComplete;

      const { playAwaitable, firstTimeUpdateAwaitable, sevenSecondsMarkAwaitable } = introVid.playMedia([
        '/video/IntroSq.webm',
        '/video/IntroSq.mp4',
      ]);

      introVid.videoElement.hidden = true;
      await playAwaitable;
      await firstTimeUpdateAwaitable;
      introVid.videoElement.hidden = false;

      await introVid.fadeIn(1.5);
      purgeAnyBoomerangVideos();

      await sevenSecondsMarkAwaitable;
      introVid.fadeOutAndRemove(1.5);

      ConversionReporting.reportConversion('pass_intro_video');
      events.userConversionCheckpoint.emit('pass_intro_video');
    },
    display_landing_page: async () => {
      if (urlParams.skipSplash) return;

      const readySplash = new LandingSplashScreen();
      splashScreens.add(readySplash);
      // errorCleanUpFunctions.push(() => readySplash.remove());
      readySplash.fadeIn(2);
      await readySplash.waitUntilDismissed();
      readySplash.remove();

      ConversionReporting.reportConversion('pass_landing_screen');
      events.userConversionCheckpoint.emit('pass_landing_screen');

      // await promiseOnClickOrKeyPress(document.body);
      await delay(1.4);
    },
    display_welcome_page_1: async () => {
      if (urlParams.skipSplash) return;

      const { IntroductionSplashScreen } = await import('@/html/components/IntroductionSplashScreen');
      const introductionSplash = new IntroductionSplashScreen();
      splashScreens.add(introductionSplash);
      await introductionSplash.fadeIn(0.5);
      await introductionSplash.waitUntilDismissed();
      await introductionSplash.fadeOutAndRemove(0.5);
    },
    display_welcome_page_1_sub: async () => {
      if (urlParams.skipSplash) return;

      const { IntroductionSplashScreenWithSub } = await import('@/html/components/IntroductionSplashScreenWithSub');
      const introductionSplash = new IntroductionSplashScreenWithSub();
      splashScreens.add(introductionSplash);

      await introductionSplash.fadeIn(0.5);
      await introductionSplash.waitUntilDismissed();
      await introductionSplash.fadeOutAndRemove(0.5);
    },
    display_welcome_page_2: async () => {
      if (urlParams.skipSplash) return;

      const { WebScreen } = await import('@/html/components/WebScreen');
      const newIntroductionSplash = new WebScreen();
      splashScreens.add(newIntroductionSplash);
      await newIntroductionSplash.waitUntilSignUpClicked();
      newIntroductionSplash.remove();
    },
    display_welcome_page_2_sub: async () => {
      if (urlParams.skipSplash) return;

      const { WebScreen } = await import('@/html/components/WebScreen');
      const newIntroductionSplash = new WebScreen();
      newIntroductionSplash.actionButtonLabel = 'Subscribe';
      splashScreens.add(newIntroductionSplash);
      await newIntroductionSplash.waitUntilSignUpClicked();
      newIntroductionSplash.remove();
    },
    display_welcome_page_3: async () => {
      if (urlParams.skipSplash) return;

      const welcomeSplash = new WelcomeSplashScreen();
      splashScreens.add(welcomeSplash);
      // errorCleanUpFunctions.push(() => welcomeSplash.remove());
      welcomeSplash.fadeIn(2);
      await welcomeSplash.waitUntilDismissed();
      welcomeSplash.remove();
    },
    display_share_page: async () => {
      const storedShifterName = await userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;

      await displayShareSplash(splashScreens.container, shifterName);
    },
    display_subscription_page_if_not_subscribed: async () => {
      const isUserOnAnySubscriptionPlan = await getIsUserOnAnySubscriptionPlan();
      if (!isUserOnAnySubscriptionPlan) {
        await displaySubscriptionScreen(appDiv);
      }
    },
    display_subscription_page: async () => {
      await displaySubscriptionScreen(appDiv);
    },
    display_sub_for_ps2_page_if_no_phone_saved: async () => {
      if (userData.profile.proxy.phone) return;

      const storedShifterName = await userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;
      await displayGetNextPresenceShiftSplash(splashScreens.container, shifterName);
    },
    display_final_learn_more_page: async () => {
      const storedShifterName = await userData.profile.getFieldValue('name');
      const shifterName = storedShifterName ?? defaultVars.shifter_name;
      await displayLearnMoreSplash_EndVersion(appDiv, shifterName);
    },
    display_completion_page: async () => {
      const screen = new CompletionScreen();
      screen.shifterName = getShifterName();
      splashScreens.add(screen);
      screen.fadeIn();
    },
    go_to_root: async () => {
      window.location.href = '/';
    },
  } satisfies Record<string, FlowResolver>;

  return flowResolvers;
}
