import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { appContext } from '@/context/appContext';

export async function getFirebaseStorageAssetUrl(assetFileName: string) {
  if (appContext.urlParams.localAssets) {
    return `/.ignored-assets/glb/${assetFileName}`;
  }

  const storage = getStorage();
  const storageRef = ref(storage, `mpv1-assets/${assetFileName}`);

  try {
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error(`Failed to get download URL for asset: ${assetFileName}`, error);
    throw error;
  }
}
