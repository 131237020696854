import { Object3D, Vector3Like, Vector3, Sprite, SpriteMaterial, TextureLoader } from 'three';
import { lerp } from 'three/src/math/MathUtils.js';

const bounds = [1000, 500, 1000] as [number, number, number];

export function createBubbleParticlesCtrl(scene: Object3D) {
  function addDynamicBubbles(bubbleCount: number) {
    const origins = range(bubbleCount).map(
      () =>
        new Vector3(
          lerp(-bounds[0], bounds[0], Math.random()),
          lerp(-bounds[1], bounds[1], Math.random()),
          lerp(-bounds[2], bounds[2], Math.random())
        )
    );

    const particles = [] as BubbleParticle[];

    for (const origin of origins) {
      const particle = new BubbleParticle();
      scene.add(particle);
      particles.push(particle);

      particle.scale.setScalar(3);
      particle.position.copy(origin);
    }

    const maxFlyDist = 100;
    const flySpeed = 0.1;

    let lastTime = performance.now();
    function animate() {
      requestAnimationFrame(animate);

      const deltaTime = (performance.now() - lastTime) / 1000;
      lastTime = performance.now();

      for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];
        const origin = origins[i];

        if (particle.life <= 0) {
          origin.set(
            lerp(-bounds[0], bounds[0], Math.random()),
            lerp(0, bounds[1], Math.random()),
            lerp(-bounds[2], bounds[2], Math.random())
          );
          particle.life = 0.5 + Math.random();
        } else {
          particle.life -= deltaTime * flySpeed;
        }

        particle.position.y = lerp(origin.y, origin.y - maxFlyDist, particle.life);
      }
    }
    animate();
  }

  function addStaticBubbles(bubbleCount: number) {
    const particles = [] as BubbleParticle[];
    for (let i = 0; i < bubbleCount; i++) {
      const particle = new BubbleParticle();
      scene.add(particle);
      particles.push(particle);

      particle.scale.setScalar(lerp(1, 8, Math.random()));
      particle.position.set(
        lerp(-bounds[0], bounds[0], Math.random()),
        lerp(0, bounds[1], Math.random()),
        lerp(-bounds[2], bounds[2], Math.random())
      );
    }
  }

  function sprayBubbles(at: Vector3Like, radius: number, ratePerSec: number, duration: number) {
    const mngr = new TempParticlesManager(scene);

    const velocityMulSideways = 0.2;
    const velocityMulRising = 2;

    let timeLeftForSpewing = duration;
    const spew = (count: number) => {
      for (let i = 0; i < count; i++) {
        const particle = new BubbleParticle();
        scene.add(particle);

        mngr.addParticle(particle);

        particle.scale.setScalar(lerp(4, 12, Math.random() ** 2));

        particle.position.set(
          lerp(at.x - radius, at.x + radius, Math.random()),
          lerp(at.y - radius, at.y + radius, Math.random()),
          lerp(at.z - radius, at.z + radius, Math.random())
        );

        particle.life = 0.5 + Math.random();
        particle.velocity = new Vector3(
          velocityMulSideways * lerp(-1, 1, Math.random()),
          velocityMulRising,
          velocityMulSideways * lerp(-1, 1, Math.random())
        );
      }
    };

    let lastTime = performance.now();
    let debt = 0;
    function animate() {
      requestAnimationFrame(animate);

      const deltaTime = (performance.now() - lastTime) / 1000;
      lastTime = performance.now();

      if (timeLeftForSpewing > 0) {
        timeLeftForSpewing -= deltaTime;

        const countToSpew = debt + deltaTime * ratePerSec;
        if (countToSpew >= 1) {
          debt = 0;
          spew(countToSpew);
        } else {
          debt += countToSpew;
        }
      }

      mngr.update(deltaTime);
    }
    animate();
  }

  return {
    sprayBubbles,
    addStaticBubbles,
    addDynamicBubbles,
  };
}

class TempParticlesManager {
  private particles: BubbleParticle[] = [];

  constructor(private readonly scene: Object3D) {}

  addParticle(particle: BubbleParticle) {
    this.particles.push(particle);
  }

  update(deltaTime: number) {
    for (let i = this.particles.length - 1; i >= 0; i--) {
      const particle = this.particles[i];
      if (particle.life <= 0) {
        this.scene.remove(particle);
        this.particles.splice(i, 1);
        continue;
      } else {
        particle.life -= deltaTime;
        particle.position.add(particle.velocity);
      }
    }
  }
}

class BubbleParticle extends Sprite {
  private static material: SpriteMaterial = new SpriteMaterial({
    color: 0xffffff,
    opacity: 0.8,
    transparent: true,
    map: new TextureLoader().load('textures/bubbl2.webp'),
  });

  life: number;
  velocity: Vector3;

  constructor() {
    super(BubbleParticle.material);

    this.life = 1;
    this.velocity = new Vector3();
  }
}

function range(end: number) {
  return Array.from({ length: end }, (_, i) => i);
}
