type ExcludeThenFunction<T> = T extends { then: Function } ? never : T;

/**
 * Creates a delegate function that allows adding and removing subscribers.
 * Subscribers are functions that get called when the delegate function is invoked.
 * @returns An object with methods to call all subscribers, add a subscriber, and remove a subscriber.
 */
export function createDelegateFunction<TParams extends any[] = [], TReturn extends any = void>() {
  type ValidReturnType = ExcludeThenFunction<TReturn> | void;
  const subscribers = new Set<(...args: TParams) => ValidReturnType>();

  /**
   * Calls all subscriber functions with the provided arguments.
   * @param args The arguments to pass to the subscriber functions.
   * @returns An array of objects, each representing the outcome of a subscriber function.
   * Each object has a status property (either 'fulfilled' or 'rejected') and a value or reason property (representing the return value or error).
   */
  function callAllSubscribers(...args: TParams) {
    const subscribersList = [...subscribers];
    const results = subscribersList.map(fn => {
      try {
        const value = fn(...args);
        return { status: 'fulfilled', value };
      } catch (error) {
        return { status: 'rejected', reason: error };
      }
    });
    return results;
  }

  /**
   * Adds a subscriber function.
   * @param fn The function to add as a subscriber.
   * @returns A function that removes the added subscriber when called.
   */
  function addSubscriber(fn: (...args: TParams) => ValidReturnType) {
    subscribers.add(fn);
    return () => removeSubscriber(fn);
  }

  /**
   * Removes a subscriber function.
   * @param fn The function to remove as a subscriber.
   * @throws {Error} If the function is not a subscriber.
   */
  function removeSubscriber(fn: (...args: TParams) => ValidReturnType) {
    if (!subscribers.has(fn)) {
      throw new Error(`Subscriber not found`);
    }
    subscribers.delete(fn);
  }

  /**
   * Adds a subscriber function that only gets called once.
   * @param fn The function to add as a subscriber.
   */
  function addOneOffSubscriber(fn: (...args: TParams) => ValidReturnType) {
    const removeAfterCalled = (...args: TParams) => {
      fn(...args);
      removeSubscriber(removeAfterCalled);
    };
    subscribers.add(removeAfterCalled);
  }

  /**
   * Removes all subscriber functions.
   */
  function clearSubscribers() {
    subscribers.clear();
  }

  /**
   * Waits until the delegate function is called and the provided comparison function returns true.
   * @param comparisonFn The comparison function to call with the delegate function's parameters.
   * @returns A promise that resolves the next time the delegate function is called and the comparison function returns true.
   */
  function waitUntil(comparisonFn: (...args: TParams) => boolean = () => true) {
    return new Promise<TParams[0]>(resolve => {
      const removeSubscriber = addSubscriber((...args) => {
        if (!comparisonFn(...args)) {
          return;
        }

        removeSubscriber();

        // @ts-ignore
        resolve(...args);
      });
    });
  }

  return Object.assign(callAllSubscribers, {
    emit: callAllSubscribers,
    on: addSubscriber,
    off: removeSubscriber,
    once: addOneOffSubscriber,
    waitUntil,
    clear: clearSubscribers,
  });
}
