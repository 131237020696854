import { MusicTracks } from './audio/music';
import { ensureUserAuthenticated } from './backend/ensureUserSignedIn';
import { buildVars } from './constants/buildVars';
import { appContext } from './context/appContext';
import { initializeDevThingsForApp } from './debug/initializeDevThingsForApp';
import { runDevSandbox } from './debug/runDevSandbox';
import { runCustomFlow } from './flow/runCustomFlow';
import { runDefaultFlow } from './flow/runDefaultFlow';
import { runOneSpecificCinematicFlow } from './flow/runOneSpecificCinematicFlow';
import { runSignInFlow } from './flow/runSignInFlow';
import { runSubscriptionFlow } from './flow/runSubscriptionFlow';
import { showSettingsButtonSometime } from './flow/showSettingsButtonSometime';
import { getOneSpecificCinematicSlugToPlay } from './flow/utils/getOneSpecificCinematicSlug';
import { getShouldRunSignInFlow } from './flow/utils/getShouldRunSignInFlow';
import { getShouldShowSubscriptionScreenFlag } from './flow/utils/getShouldShowSubscriptionScreenFlag';
import { trackOrientationAsCSSClass } from './helpers/trackOrientationAsCSSClass';
import { addAppVersionIndicator } from './html/system/addAppVersionIndicator';
import { displayErrorMessage } from './html/system/displayErrorMessage';
import { ensureAllAssetsLoaded } from './scene/ensureAllAssetsLoaded';
import { captureUTMParams } from './tools/captureUTMParams';
import { disableZoomGestures } from './util/browser/disableZoomGestures';
import { transformUrl } from './util/browser/transformUrl';
import { tryToResumeHowlerContextOnClick } from './util/browser/tryToResumeHowlerContextOnClick';
import { performPasswordProtectionCheck } from './util/performPasswordProtectionCheck';

import '@/styles/index.scss';
import '@/styles/keyframes.scss';
import '@/styles/mobile.scss';
import '@/styles/ui.scss';

const APP_VERSION = buildVars.appBuildVersion ?? 'unknown';

export async function main() {
  console.log(`🚀 Starting...\n🎤 App version: ${APP_VERSION}`);

  addAppVersionIndicator(appContext.appDiv, APP_VERSION);

  ensureAllAssetsLoaded();

  initExtraThings();

  try {
    await ensureUserAuthenticated();
  } catch (error) {
    console.error(error);
    return displayErrorMessage(String(error), 'Signing in failed.');
  }

  try {
    ////
    //// SHIFT SCRIPTS PLAYBACK
    ////

    if (appContext.urlParams.flow) {
      console.log('🎬 Flow override:', appContext.urlParams.flow);
      const flowKeys = String(appContext.urlParams.flow)
        .split(',')
        .map(key => key.trim());
      await runCustomFlow(flowKeys);
    }

    const showSignInScreenFirst = getShouldRunSignInFlow();
    if (showSignInScreenFirst) {
      //// SHOW SIGN IN SCREEN FIRST
      console.log('🎬 Showing sign in screen');
      await runSignInFlow();
    }

    const showSubscriptionScreenFirst = getShouldShowSubscriptionScreenFlag();
    if (showSubscriptionScreenFirst) {
      //// SHOW SUBSCRIPTION SCREEN FIRST
      console.log('🎬 Showing subscription screen');
      await runSubscriptionFlow();
    }

    const cinematicScriptSlugToPlay = getOneSpecificCinematicSlugToPlay();
    if (cinematicScriptSlugToPlay) {
      //// SHIFT SCRIPTS PLAYBACK EXPLICIT
      console.log('🎬 Playing specific cinematic script', cinematicScriptSlugToPlay);
      await runOneSpecificCinematicFlow();
    } else {
      //// SHIFT SCRIPTS PLAYBACK DEFAULT
      console.log('🎬 Playing default flow');
      await runDefaultFlow();
    }
  } catch (error) {
    console.error(error);
    displayErrorMessage(String(error));
  }
}

function initExtraThings() {
  const { appDiv, urlParams, music } = appContext;

  if (import.meta.env.PROD) {
    import('@/sentry/sentry').then(() => console.log('🕶 Sentry initialized'));
  }

  transformUrl(false);

  initializeDevThingsForApp();

  disableZoomGestures();

  //// Google Analytics
  requestAnimationFrame(() =>
    captureUTMParams((key, value) => {
      gtag('set', { [key]: value });
    })
  );

  //// Try to play music
  console.log('🎶 Playing music');
  music.playTrack(MusicTracks.Main);

  tryToResumeHowlerContextOnClick();

  //// Updates the orientation class on the app div
  trackOrientationAsCSSClass(appDiv);

  //// Mute the sound if necessary
  function getShouldMute() {
    if (urlParams.mute !== undefined) return urlParams.mute;
    return false;
  }
  const shouldMute = getShouldMute();
  appContext.setMuted(shouldMute);

  //// Show settings button at the appropriate time
  showSettingsButtonSometime();
}

if (appContext.urlParams.sandbox) {
  runDevSandbox();
} else {
  const passwordCheckPassed = performPasswordProtectionCheck();
  if (passwordCheckPassed) {
    main();
  } else {
    const { appDiv } = appContext;
    const contactEmail = atob('c2VhbkBvbmVwZXJmZWN0LmNvbQ==');
    appDiv.innerHTML = `Contact ${contactEmail} for a demo :)`;
  }
}
