type Target = HTMLElement;
type VisibilityUpdateCallback = (visibility: number) => unknown;

export function createAnimatedElementVisibilityControl(
  element: Target,
  parent: HTMLElement,
  fadeInDuration: number,
  fadeOutDuration: number,
  updateVisibilityCallback: VisibilityUpdateCallback
) {
  let visibility = 0.0;
  let isVisible = false;
  let animationId: number | null = null;

  const fadeInCoeff = 1 / fadeInDuration;
  const fadeOutCoeff = 1 / fadeOutDuration;

  updateVisibilityCallback(visibility);

  function updateVisibility(deltaTime: number) {
    if (isVisible && visibility < 1) {
      visibility = Math.min(visibility + fadeInCoeff * deltaTime, 1);
    } else if (!isVisible && visibility > 0) {
      visibility = Math.max(visibility - fadeOutCoeff * deltaTime, 0);
    }

    updateVisibilityCallback(visibility);

    if (visibility > 0.01 && element.parentElement !== parent) {
      parent.appendChild(element);
    } else if (visibility <= 0.01 && element.parentElement === parent) {
      parent.removeChild(element);
    }

    animationId = requestAnimationFrame(animate);
  }

  let lastTime = performance.now();
  function animate(currentTime: number) {
    const deltaTime = (currentTime - lastTime) / 1000;
    lastTime = currentTime;
    updateVisibility(deltaTime);
  }

  animationId = requestAnimationFrame(animate);

  return {
    setVisible(visible: boolean) {
      isVisible = visible;
    },
  };
}
