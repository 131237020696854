import { appContext } from '@/context/appContext';

// Define the global state object
type GlobalState = Record<string, any>;
const globalState: GlobalState = {};

// Function to create or update the debug box
function updateDebugBox(): void {
  let debugBox = document.getElementById('debugBox') as HTMLDivElement;
  if (!debugBox) {
    debugBox = document.createElement('div');
    debugBox.id = 'debugBox';
    Object.assign(debugBox.style, {
      backgroundColor: '#000b',
      borderRadius: '1em',
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: '1000',
      padding: '1em',
      textAlign: 'right',
    });
    document.body.appendChild(debugBox);
  }

  // Clear existing content
  debugBox.innerHTML = '';

  // Populate with globalState data
  Object.entries(globalState).forEach(([key, value]) => {
    const keyElement = document.createElement('div');
    keyElement.style.fontSize = '.7em';
    keyElement.style.color = '#ff09';
    keyElement.textContent = key;
    debugBox.appendChild(keyElement);

    //// if value is object, attempt to josn.stringify it, otherwise just stringify it
    const valueString = typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value);
    const valueElement = document.createElement('pre');
    valueElement.textContent = valueString;
    valueElement.style.color = '#ff0';
    valueElement.style.padding = '0';
    valueElement.style.margin = '0';
    valueElement.style.textAlign = 'left';
    debugBox.appendChild(valueElement);

    const spacer = document.createElement('div');
    spacer.style.marginBottom = '10px';
    debugBox.appendChild(spacer);
  });
}

// Exported function to update the global state and refresh the debug box
export function updateWatchedVar(key: string, value: any = '✔'): void {
  if (!appContext.urlParams.debugState) return;

  globalState[key] = value;
  updateDebugBox();
}

export function deleteWatchedVar(key: string): void {
  if (!appContext.urlParams.debugState) return;

  delete globalState[key];
  updateDebugBox();
}
