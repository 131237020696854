import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('blurry-wrapper')
export class BlurryWrapper extends LitElement {
  static styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: fixed;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      overflow: auto;
    }

    .blurry-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      width: 100%;
      margin: auto 0;

      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(10px);
    }
  `;

  @property({ type: Number }) backgroundBlurriness = 10;
  @property({ type: Number }) backgroundDimness = 0.5;
  @property({ type: Number }) zIndex = -1;

  @property({ type: Number }) visibility = 1;

  render() {
    const wrapperStyle = `
      backdrop-filter: blur(${this.backgroundBlurriness * this.visibility}px);
      background-color: rgba(0, 0, 0, ${this.backgroundDimness * this.visibility});
      z-index: ${this.zIndex};
    `;

    return html`
      <div class="blurry-wrapper" style=${wrapperStyle}>
        <slot></slot>
      </div>
    `;
  }
}

@customElement('dimmy-wrapper')
export class DimmyWrapper extends LitElement {
  static styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgba(0, 0, 0, 0.5);

      z-index: -1;
      overflow: auto;
    }
  `;

  render() {
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'blurry-wrapper': BlurryWrapper;
  }
}
