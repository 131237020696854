import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';
import { ReusableStyles } from '../common/styles';

import '../common/AgeConfirmationCheckbox';
import '../common/BlurryWrapper';
import '../common/PlatformLoginButton';
import '../common/SmallSpinner';
import './ModernInput';
import './TOSFooter';

const TAG_NAME = 'sub-for-presence-shift-2';

const defaultFormData = {
  phone: '',
  countryCode: '+1',
};
type FormData = typeof defaultFormData;

@customElement(TAG_NAME)
export class SubscribeForPresenceShift2 extends LitElement {
  static readonly tagName = TAG_NAME;

  static readonly styles = css`
    :host {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      zoom: 0.75;
    }

    :host(.portrait) {
      zoom: 0.55;
    }

    .form-container {
      width: 90%;
      max-width: 1280px;
      margin: auto;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;

      background: #0008;
      border-radius: 4vmin;
      padding: 10vmin;
      box-sizing: border-box;
      position: relative;
    }

    .form-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 480px;
      position: relative;
    }

    form {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
    }

    .form-title {
      font-size: 24px;
      margin-bottom: 20px;
      color: #fff;
      text-align: center;
      font-family: Lora;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 106.667% */
      letter-spacing: 0.6px;
    }

    .form-subtitle {
      color: #fff;
      text-align: center;
      font-family: Lora;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 52px; /* 108.333% */
      letter-spacing: 0.48px;
    }

    .input-container {
      display: flex;
      align-items: center;
      margin-top: 1em;
      width: 100%;
    }

    modern-input {
      flex-grow: 1;
    }

    .country-code {
      width: 20%;
      margin-right: 0.5em;
    }

    button {
      margin-top: 1.5rem;
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 16px;
      font-family: Inter;
      font-weight: 600;
      color: #fff;
      background-color: #3b3b3b;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: #5a5a5a;
    }

    .small-text {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.14px;
    }

    .mid-text {
      color: #999;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 116.667% */
      letter-spacing: 0.24px;
      position: relative;
      display: block;
    }

    hr {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      width: 90%;
    }

    .link {
      color: #fff9;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 2em;
    }

    glowy-spinny-dot {
      margin-top: 2.5em;
    }

    .shift-num {
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-family: Lora;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 64px; /* 177.778% */
      letter-spacing: 0.36px;
    }

    .coming-soon {
      margin-top: 0.3em;

      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.2px;

      background: linear-gradient(165deg, rgba(177, 187, 189, 0.7) -21.23%, rgba(18, 40, 40, 0.7) 142.24%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    tos-footer {
      margin-top: 2.5em;
    }

    .btn-advance {
      color: #999;
      cursor: pointer;
      text-decoration: none;
      margin-top: 2em;
    }

    .skip-link {
      text-decoration: underline;
    }

    ${ReusableStyles.glassButton}

    button.glass-button {
      max-width: 80%;
      width: 50vmin;
    }

    .space {
      height: 1em;
    }
  `;

  readonly data = { ...defaultFormData };

  @query('button.subscribe-button')
  private readonly subscribeButton!: HTMLButtonElement;

  public readonly onSubmit = createDelegateFunction<[data: FormData]>();

  public readonly onSkipClick = createDelegateFunction();

  @property({ type: String }) shifterName = '';

  @property({ type: Boolean }) loading = false;

  handleInputChange(event: CustomEvent) {
    if (!event.target) {
      console.error('No target found for input change event');
      return;
    }

    const inputElement = event.target as HTMLInputElement | HTMLSelectElement;
    const inputName = inputElement.name;

    if (!inputName) {
      console.error('No input name found for input change event');
      return;
    }

    this.data[inputName as keyof FormData] = inputElement.value;
  }

  firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  render() {
    return html`
      <div class="form-container">
        <div class="form-title">Subscribe for Presence Shift 2 now, ${this.shifterName}</div>
        <div class="form-content">
          <glowy-spinny-dot scale="1.2">
            <div class="shift-num">2</div>
          </glowy-spinny-dot>

          <div class="form-subtitle">Presence Shift 2</div>
          <div class="coming-soon">Coming Soon...</div>

          <div class="space"></div>
          <div class="space"></div>
          <div class="space"></div>
          <div class="space"></div>

          <div class="mid-text">We’ll text you when PS2 is ready if you add your mobile number below.</div>

          <div class="mid-text">(with country code)</div>

          <form @submit=${this.handleSubmit}>
            <modern-input
              name="phone"
              type="tel"
              placeholder="+1 200-555-5555"
              @input-change=${this.handleInputChange}
            ></modern-input>

            <button
              id="subscribe-for-presence-shift-2-button"
              type="submit"
              class="subscribe-button glass-button"
              ?disabled=${this.loading}
            >
              ${this.loading ? html`<small-spinner size="1em"></small-spinner>` : html`Subscribe`}
            </button>
          </form>

          <div id="maybe-later-button-presence-shift-2" class="btn-advance skip-link" @click="${this.handleClickSkip}">
            Maybe later
          </div>

          <!-- <age-confirmation-checkbox></age-confirmation-checkbox> -->
          <!-- <tos-footer verb="subscribing"></tos-footer> -->
        </div>
      </div>
    `;
  }

  handleSubmit(event: Event) {
    event.preventDefault();

    this.loading = true;

    if (this.data.phone) {
      const firstChar = this.data.phone.charAt(0);
      if (firstChar !== '+') {
        this.data.phone = '+1' + this.data.phone;
      }
    }

    // Handle the form submission logic
    console.log('Form submitted', this.data);
    this.onSubmit.emit(this.data);
  }

  handleClickSkip() {
    this.onSkipClick.emit();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: SubscribeForPresenceShift2;
  }
}

// <!-- <select class="country-code" name="countryCode" @change=${this.handleInputChange}>
//     <option value="+1">+1</option>
//     <option value="+44">+44</option>
//     <option value="+91">+91</option>
//   </select> -->
