import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBVYbFn39zZdGapVlHXGDcEoXPXm7PPF5M',
  authDomain: 'tps1-9ee98.firebaseapp.com',
  projectId: 'tps1-9ee98',
  storageBucket: 'tps1-9ee98.appspot.com',
  messagingSenderId: '918964495166',
  appId: '1:918964495166:web:8756b1300e4e55c412426f',
  measurementId: 'G-1HWRYQ8YFJ',
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export const auth = getAuth(app);
auth.useDeviceLanguage();

Object.assign(window, { app, auth, db, analytics });
