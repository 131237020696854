import { LitElement, css } from 'lit';
import { html } from 'lit-html';

import gsap from 'gsap';

const TAG_NAME = 'shifter-input-ttc';

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShifterInputTapToContinue;
  }
}

class ShifterInputTapToContinue extends LitElement {
  static styles = css`
    :host {
      font-size: calc(1.2em + 20px);
      color: #fffb;
      font-family: 'Lora', serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      padding: 0.5em;
      text-align: center;

      animation: fadeIn 1.1s ease-in-out;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `;

  render() {
    return html` Tap to continue `;
  }

  removeWithAnimation(animationDuration: number) {
    return gsap.to(this, {
      duration: animationDuration,
      opacity: 0,
      scale: 1.1,
      onComplete: () => this.remove(),
    });
  }
}

customElements.define(TAG_NAME, ShifterInputTapToContinue);

export function addShifterInputTapToContinue(parentElement: Element) {
  const element = document.createElement(TAG_NAME);
  parentElement.appendChild(element);
  return element;
}
