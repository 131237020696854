import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('hover-tooltip')
class HoverTooltip extends LitElement {
  @property({ type: String }) tooltip = ''; // The tooltip content

  static styles = css`
    :host {
      display: inline-block;
      position: relative;
    }

    .tooltip-content {
      visibility: hidden;
      background-color: black;
      color: white;
      text-align: center;
      padding: 5px;
      border-radius: 4px;
      position: absolute;
      z-index: 10;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
      white-space: nowrap;
    }

    :host(:hover) .tooltip-content {
      visibility: visible;
      opacity: 1;
    }

    ::slotted(*) {
      cursor: pointer;
    }
  `;

  render() {
    return html`
      <slot></slot>
      <div class="tooltip-content">${this.tooltip}</div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'hover-tooltip': HoverTooltip;
  }
}

@customElement('tooltip-element')
export class TooltipElement extends LitElement {
  @property({ type: String }) tooltip = '';

  static styles = css`
    :host {
      position: relative;
      display: inline-block;
      cursor: pointer;
      pointer-events: all;
    }

    .tooltiptext {
      pointer-events: none;

      font-size: 20px;
      font-family: Inter, sans-serif;

      background-color: #000c;
      color: #fff;
      text-align: center;
      border-radius: 2em;
      padding: 0.75em 1.5em;

      width: 100%;

      position: absolute;
      z-index: 10000;
      top: 125%;
      left: 50%;
      transform-origin: top center;
      transform: translate(-50%, 0%) scale(0.8);
      opacity: 0;

      transition-duration: 0.3s;
      transition-property: opacity, transform;
    }

    :host(:hover) .tooltiptext {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0%) scale(1);
    }
  `;

  render() {
    return html`
      <slot></slot>
      <span class="tooltiptext">${this.tooltip}</span>
    `;
  }
}
