import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { SIGNUP_CLICK_EVENT } from './WebScreen';

@customElement('hero-section')
export class HeroSection extends LitElement {
  @property({ type: String })
  actionButtonLabel = 'Sign up';

  static readonly signUpClickEvent = 'signup';

  static override styles = css`
    :host {
      display: block;
      color: white;
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .bg-video video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      object-position: 5px 90%;
      opacity: 0.5;
      position: absolute;
      filter: blur(8px);
    }
    body {
      margin: 0px;
    }
    .bg-video {
      position: relative;
      backdrop-filter: blur(10px);
      background: rgba(0, 0, 0, 0.5);
    }
    .hero-container {
      max-width: 1290px;
      margin: 0px auto;
      padding: 20px;
    }

    .hero-title {
      font-size: 70px;
      font-weight: 500;
      line-height: 82px;
      margin-bottom: 23px;
      font-family: 'Abhaya Libre', serif;
    }

    .border-style {
      background: linear-gradient(to right, #08feb3, #0f80a8);
      width: 100px;
      height: 10px;
      margin-bottom: 30px;
      border-radius: 50px;
    }

    .top-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      margin-bottom: 80px;
    }
    .hero-text-description {
      font-size: 32px;
      font-weight: 300;
      line-height: 54px;
      letter-spacing: 1.5px;
      max-width: 1301px;
    }

    .hero-text-small-screen {
      margin-bottom: 0 px;
    }

    .text-hero-break {
      display: block;
      margin-bottom: 53px;
    }

    .hero-text-large-screen {
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 2rem;
      line-height: 38px;
      letter-spacing: normal;
      margin-bottom: 53px;
    }

    .hero-text-span {
      font-weight: 600;
      color: #08feb3;
    }

    .hero-owner {
      font-size: 40px;
      font-weight: 500;
      margin-top: 102px;
      margin-bottom: 80px;
      font-family: 'Abhaya Libre', serif;
    }

    .center-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .hero-button {
      display: inline-block;
      padding: 16px 153px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: white;
      box-shadow: 0px 6px 8px 0px #ffffff1f inset;
      box-shadow: 0px 2px 12px 0px #43ffbbb8 inset;
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      border: none;
      border-radius: 50px;
      cursor: pointer;
      text-decoration: none;
    }

    .text-below-button {
      margin-top: 13px;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      padding-bottom: 230px;
    }

    .text-below-button {
      color: #08feb3;
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
      .hero-container {
        padding: 60px;
      }

      .top-text {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 55px;
        margin-top: -15px;
      }

      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }

      .hero-title {
        font-size: 52px;
        line-height: 45px;
        margin-bottom: 16px;
      }
      .hero-text-small-screen {
        margin-bottom: 45px;
      }

      .hero-text-description {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 40px;
        max-width: 1060px;
        margin: 1px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-text-span {
        font-weight: 500;
      }

      .hero-text-large-screen {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 40px;
        margin-top: 0px;
      }

      .hero-owner {
        font-size: 32px;
        line-height: 29px;
        margin-bottom: 70px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 14px 130px;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 0px;
        padding-bottom: 130px;
      }
    }

    @media screen and (min-width: 800px) and (max-width: 1200px) {
      .hero-container {
        padding: 60px;
      }

      .top-text {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 65px;
        margin-top: 0px;
      }

      .border-style {
        width: 80px;
        height: 7px;
        margin-bottom: 30px;
      }

      .hero-title {
        letter-spacing: 0;
        font-size: 52px;
        line-height: 45px;
        margin-bottom: 16px;
      }

      .hero-text-small-screen {
        margin-bottom: 40px;
      }

      .hero-text-description {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-text-span {
        font-weight: 500;
      }

      .hero-text-large-screen {
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .hero-owner {
        font-size: 32px;
        line-height: 29px;
        margin-bottom: 80px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 14px 130px;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 20px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 0px;
        padding-bottom: 80px;
      }
    }

    @media screen and (min-width: 500px) and (max-width: 800px) {
      .hero-container {
        padding: 40px;
      }

      .top-text {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 40px;
        margin-top: 0px;
      }

      .border-style {
        width: 60px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 20px;
      }

      .hero-title {
        letter-spacing: 0;
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 5px;
      }

      .hero-text-description {
        letter-spacing: 0;
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 29px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-text-span {
        font-weight: 400;
      }

      .hide-text-large-screen {
        display: none;
      }

      .hero-text-small-screen {
        display: block;
        margin-bottom: 35px;
      }

      .hero-owner {
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 50px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 8px 80px;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        padding-bottom: 0px;
      }
    }

    @media (max-width: 500px) {
      .top-text {
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 20px;
        margin-top: 0px;
      }

      .border-style {
        width: 36.7px;
        height: 4.5px;
        border-radius: 50px;
        margin-bottom: 13px;
      }

      .hero-title {
        font-size: 23px;
        line-height: 27px;
        margin-bottom: 5px;
      }
      .hero-text-small-screen {
        margin-bottom: 29px;
      }

      .hero-text-description {
        font-size: 13px;
        font-weight: 300;
        word-spacing: 0.5px;
        letter-spacing: 0.25px;
        line-height: 18.73px;
        margin-bottom: 24px;
        margin-top: 0px;
      }

      .text-hero-break {
        display: none;
      }

      .hero-text-span {
        font-weight: 400;
      }

      .hide-text-large-screen {
        display: none;
      }

      .hero-text-small-screen {
        display: block;
      }

      .hero-owner {
        font-size: 14px;
        line-height: 29px;
        font-weight: 500;
        margin-bottom: 20px;
        margin-top: 0px;
      }

      .hero-button {
        padding: 4px 54px;
        font-size: 8px;
        font-weight: bold;
        line-height: 20px;
      }

      .text-below-button {
        font-size: 8px;
        margin-top: 5px;
        font-weight: 400;
        line-height: 13px;
        margin-bottom: 5px;
        padding-bottom: 0px;
      }
    }
  `;

  /**
   * The heading text for the hero section.
   */

  override render() {
    return html`
      <div class="bg-video">
        <div class="hero-container">
          <p class="top-text">
            THE <br />
            PRESENCE<br />
            SHIFT
          </p>
          <h1 class="hero-title">Welcome to Presence Shifting!</h1>
          <div class="border-style"></div>

          <div class="hero-text-description">
            <p class="hero-text-small-screen">
              Settle in for a incredible shift to your state of mind and body. We’ll dive into a beautiful
              conversational world of peace, love, and humor — and shift your state to your desire anytime.
            </p>
          </div>

          <p class="hero-owner">Dr. Sean Sullivan</p>
          <div class="center-content">
            <a href="#" class="hero-button" @click=${this.onSignupClick}>${this.actionButtonLabel}</a>
            <p class="text-below-button">
              Unlock Your Free
              <br />
              7-Day Starter Program
            </p>
          </div>

          <!-- <h1 class="hero-title">Welcome to Presence Shifting!</h1>
          <div class="border-style"></div>

          <div class="hero-text-description">
            <div class="hero-text-description">
              <p class="hero-text-small-screen">
                Settle in for a incredible shift to your state of mind and body. We’ll dive into a beautiful
                conversational world of peace, love, and humor — and shift your state to your desire in ~10 minutes
                anytime.
              </p>
            </div>
          </div>

          <p class="hero-owner">Dr. Sean Sullivan</p>
          <div class="center-content">
            <a href="#" class="hero-button" @click=${this.onSignupClick}>Sign up</a>
            <p class="text-below-button">
              Signing up prepares your<br />
              <span>7-Day Starter Program</span>
            </p>
          </div> -->
        </div>
      </div>
      <slot></slot>
    `;
  }

  private onSignupClick(event: Event) {
    event.preventDefault();
    this.dispatchEvent(new CustomEvent(SIGNUP_CLICK_EVENT, { bubbles: true, composed: true }));
    this.dispatchEvent(new CustomEvent(HeroSection.signUpClickEvent, { bubbles: true, composed: true }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'hero-section': HeroSection;
  }
}
