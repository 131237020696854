import { loadDataObjectFromStaticCSV } from './loadDataObjectFromStaticCSV';

export function createUserEmotionProcessor() {
  const recognizableEmotionsList = [] as string[];

  return {
    async load() {
      const dataRaw = await loadDataObjectFromStaticCSV('./data/emotions.csv');
      const data = dataRaw.flat().filter(Boolean);
      recognizableEmotionsList.push(...data);
    },
    isEmotionRecognized(input: string) {
      if (recognizableEmotionsList.length === 0) {
        throw new Error('Emotions list is empty. Did you forget to call .load()?');
      }

      return recognizableEmotionsList.includes(input);
    },
    getEmotionsList() {
      return recognizableEmotionsList as readonly string[];
    },
  };
}
