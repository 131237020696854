import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';

const hashedPasswordReal = buildVars.passwordBase64;
const localStoragaKey = 'pwd';

export function performPasswordProtectionCheck() {
  if (!hashedPasswordReal) {
    return true;
  }

  let hashedPasswordCandidate = localStorage.getItem(localStoragaKey) || null;

  if (appContext.urlParams.password) {
    hashedPasswordCandidate = btoa(appContext.urlParams.password);
    delete appContext.urlParams.password;
  } else if (hashedPasswordCandidate === null) {
    const enteredPassword = window.prompt('Please enter the password');
    hashedPasswordCandidate = btoa(enteredPassword || '');
  }

  const isPasswordCorrect = hashedPasswordCandidate === hashedPasswordReal;

  if (!isPasswordCorrect) {
    localStorage.removeItem(localStoragaKey);
    return false;
  }

  localStorage.setItem(localStoragaKey, hashedPasswordCandidate);

  return true;
}
