export module ModelNodeNames {
  export const Dolphin1 = {
    body: 'Dolphin_rig_v003dolphin_body',
    eyeL: 'Dolphin_rig_v003dolphin_L_Eye',
    eyeR: 'Dolphin_rig_v003dolphin_R_Eye',
  };

  export const Dolphin2 = {
    body: 'Dolphin_dolphin_body',
    eyeL: 'Dolphin_dolphin_L_Eye',
    eyeR: 'Dolphin_dolphin_R_Eye',
  };
}
