export function createDelegateFunctionAsync<
  TParams extends any[] = [],
  TReturn extends { then?: Function } | void = { then?: Function } | void,
>() {
  const subscribers = new Set<(...args: TParams) => TReturn>();

  function callAllSubscribers(...args: TParams) {
    const subscribersList = [...subscribers];
    const results = subscribersList.map(fn => fn(...args));
    return Promise.allSettled(results);
  }

  function callAllSubscribersAndClear(...args: TParams) {
    const subscribersList = [...subscribers];
    subscribers.clear();

    const results = subscribersList.map(fn => fn(...args));
    return Promise.allSettled(results);
  }

  function addSubscriber(fn: (...args: TParams) => TReturn) {
    subscribers.add(fn);
    return () => removeSubscriber(fn);
  }

  function removeSubscriber(fn: (...args: TParams) => TReturn) {
    if (!subscribers.has(fn)) {
      console.error('🎤 Removed non-existent subscriber');
    }
    subscribers.delete(fn);
  }

  function addOneOffSubscriber(fn: (...args: TParams) => TReturn) {
    const removeAfterCalled = (...args: TParams) => {
      try {
        removeSubscriber(removeAfterCalled);
      } finally {
        return fn(...args);
      }
    };
    subscribers.add(removeAfterCalled);
  }

  function clearSubscribers() {
    subscribers.clear();
  }

  function waitUntil(comparisonFn: (...args: TParams) => boolean = () => true) {
    return new Promise<void>(resolve => {
      const removeSubscriber = addSubscriber((...args) => {
        if (comparisonFn(...args)) {
          removeSubscriber();
          resolve();
        }

        return Promise.resolve() as unknown as TReturn;
      });
    });
  }

  return Object.assign(callAllSubscribers, {
    emit: callAllSubscribers,
    emitAndClear: callAllSubscribersAndClear,
    on: addSubscriber,
    off: removeSubscriber,
    once: addOneOffSubscriber,
    waitUntil,
    clear: clearSubscribers,
  });
}
