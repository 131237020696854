import { appContext } from '@/context/appContext';

const CSS_CLASS_LANDSCAPE = 'landscape';
const CSS_CLASS_PORTRAIT = 'portrait';

export function trackOrientationAsCSSClass(element: HTMLElement) {
  appContext.orientationTracker.addListener(({ isLandscape }) => {
    if (isLandscape) {
      element.classList.add(CSS_CLASS_LANDSCAPE);
      element.classList.remove(CSS_CLASS_PORTRAIT);
    } else {
      element.classList.add(CSS_CLASS_PORTRAIT);
      element.classList.remove(CSS_CLASS_LANDSCAPE);
    }
  }, true);
}
