import { MultipleReasons } from '@/util/core/MultipleReasons';
import { ObservableValue } from '@/util/core/ObservableValue';

export function createFastForwardingControl() {
  const reasonsToPreventFastForwarding = new MultipleReasons();

  const isEnabled = new ObservableValue(false);
  isEnabled.onChange.on(enabled => reasonsToPreventFastForwarding.set('not-enabled', !enabled));

  const isFastForwarding = new ObservableValue(false);
  reasonsToPreventFastForwarding.on({
    nonEmpty: () => {
      if (isFastForwarding.get() === false) return;
      isFastForwarding.set(false);
    },
  });

  const canFastForward = new ObservableValue(!reasonsToPreventFastForwarding.hasAny());
  reasonsToPreventFastForwarding.on({
    change: hasReasons => canFastForward.set(hasReasons === false),
  });

  const control = {
    reasonsToPreventFastForwarding,

    isEnabled,
    isFastForwarding,
    canFastForward,

    start() {
      if (isFastForwarding.get()) {
        return console.warn('🔄 Fast forwarding already started');
      }

      if (!isEnabled.get()) {
        return console.warn('🔄 Fast forwarding not enabled');
      }

      if (reasonsToPreventFastForwarding.hasAny()) {
        return console.warn(`🔄 Fast forwarding prevented. Reson: ${reasonsToPreventFastForwarding}`);
      }

      isFastForwarding.set(true);

      console.log('🔄 Fast forwarding started');
    },

    stop() {
      if (!isFastForwarding.get()) {
        return console.warn('🔄 Fast forwarding already stopped');
      }

      isFastForwarding.set(false);

      console.log('🔄 Fast forwarding stopped');
    },

    dispose() {
      if (isFastForwarding.get()) {
        this.isFastForwarding.set(false);
      }

      isEnabled.onChange.clear();
      isFastForwarding.onChange.clear();
      reasonsToPreventFastForwarding.removeAll();
    },
  };

  return Object.freeze(control);
}

export type FastforwardingControl = ReturnType<typeof createFastForwardingControl>;
