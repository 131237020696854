function reviveAudioContextIfNecessary() {
  if (Howler.ctx?.state === 'suspended') {
    console.warn('🎶 Resuming Howler context, as it was suspended.');
    Howler.ctx?.resume();
  }

  // if (Howler.ctx?.state === 'closed') {
  //   console.warn('🎶 Creating new Howler context, as the previous was closed.');
  //   const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   Howler.ctx = newAudioContext;
  // }
}

export function tryToResumeHowlerContextOnClick() {
  document.addEventListener('click', () => {
    reviveAudioContextIfNecessary();
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      reviveAudioContextIfNecessary();
    }
  });
}

/// make sure webkitAudioContext doesn't give ts error
declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}
