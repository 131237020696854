import { displayErrorMessage } from '@/html/system/displayErrorMessage';
import { FlowResolver } from './FlowResolver.type';
import { createSceneContext, SceneContext } from '@/context/sceneContext';

export async function runFlow(flow: string[], flowResolvers: Record<string, FlowResolver>) {
  if (!flow || flow.length === 0) {
    console.warn('🚀 No flow provided');
    displayErrorMessage(
      'No flow provided',
      'Please check your configuration file and make sure you have a valid flow defined.'
    );
    return;
  }

  const resolverContext = createFlowResolversContext();
  
  // Get the starting index from URL hash if it's specified and valid
  const urlHash = window.location.hash.substring(1);
  let currentIndex = (!isNaN(parseInt(urlHash)) && parseInt(urlHash) < flow.length) ? parseInt(urlHash) : 0;

  for (let i = Math.max(0, currentIndex - 1); i < flow.length; i++) {
    const flowKey = flow[i];

    // Skip disabled flow items
    if (flowKey.toLowerCase().startsWith('disable:')) {
      continue;
    }

    // Set the URL hash to the current index
    window.location.hash = String(i + 1);

    const flowResolver = flowResolvers[flowKey];

    if (!flowResolver) {
      displayErrorMessage('Unknown flow item', `Cannot resolve flow item: ${flowKey}`);
      continue;
    }

    try {
      console.log(`🚀 🚀 🚀 Running flow item: ${flowKey}`);

      await flowResolver(resolverContext);
    } catch (error) {
      console.error('🐬 Error running flow item:', flowKey, error);
      displayErrorMessage(String(error), 'Error occurred during flow');
    }
  }
}

function createFlowResolversContext() {
  let sceneContext = null as SceneContext | null;

  return {
    loadSceneContext: async () => {
      if (!sceneContext) {
        sceneContext = await createSceneContext();
        await sceneContext.animateIn();
      }

      return sceneContext;
    },
  };
}
