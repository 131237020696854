import { auth } from '@/backend/firebase';
import { appContext } from '@/context/appContext';
import { onAuthStateChanged } from 'firebase/auth';

/**
 * Enables event tracking via Google Tag Manager and Facebook Pixel.
 */
export function enableEventTracking() {
  const gtag = window.gtag;

  if (!gtag) {
    console.error('Google Analytics not loaded');
    return;
  }

  onAuthStateChanged(auth, user => {
    if (!user) return;

    gtag('set', { user_id: user.uid });
    fbq('set', 'user_properties', { user_id: user.uid });

    if (user.isAnonymous) return;

    const firstSignIn = user.metadata.creationTime === user.metadata.lastSignInTime;
    const platform = user.providerData[0]?.providerId;

    {
      const eventName = firstSignIn ? 'user_signed_up' : 'user_signed_in';
      gtag('event', eventName, { user_id: user.uid, platform });
      console.log('📊 GTAG EVENT:', eventName, { user_id: user.uid, platform });
    }

    {
      if (firstSignIn) {
        fbq('track', 'CompleteRegistration', { user_id: user.uid, platform });
      } else {
        fbq('trackCustom', 'LogIn', { user_id: user.uid, platform });
      }
    }
  });

  const { events } = appContext;

  events.shiftStarted.on(shiftContext => {
    const { scriptSlug } = shiftContext;

    gtag('event', 'cinematic_started', { scriptSlug });
    fbq('trackCustom', 'CinematicStarted', { scriptSlug });
  });

  events.shiftFinished.on(shiftContext => {
    const { scriptSlug } = shiftContext;

    gtag('event', 'cinematic_finished', { scriptSlug });
    fbq('trackCustom', 'CinematicFinished', { scriptSlug });

    gtag('set', { last_cinematic: scriptSlug });
  });

  events.socialShare.on(platform => {
    gtag('event', 'social_share', { platform });
    fbq('trackCustom', 'Share', { platform });
  });

  events.purchaseCompleted.on(itemKey => {
    gtag('event', 'purchase_completed', { itemKey });
    fbq('track', 'Purchase', { itemKey });
  });

  // events.displaySplashScreen.on(splashScreenSlug => {
  //   gtag('event', 'view_splash_screen', { splashScreenSlug });
  // });

  events.userConversionCheckpoint.on(checkpoint => {
    gtag('event', 'user_conversion_checkpoint', { checkpoint });
    fbq('trackCustom', 'UserConversion', { checkpoint });
  });

  events.subscriptionCheckoutStarted.on(plan => {
    fbq('track', 'InitiateCheckout', { plan });
    gtag('event', 'initiate_checkout', { plan });
  });

  events.subscriptionCheckoutCompleted.on(plan => {
    fbq('track', 'StartTrial', { value: '0.00', currency: 'USD', predicted_ltv: '0.00', plan });
    gtag('event', 'start_trial', { plan });
  });
}
