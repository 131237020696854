import { ShiftContext } from '@/context/shiftContext';
import { defaultScriptItemProperties } from '@/data/defaultScriptItemProperties';
import { createStringyVarsContext } from '@/services/createStringyVarsContext';
import { processStringWithVars } from '@/tools/processString';
import { deepMerge } from '@/util/core/deepMerge';
import { executeFunctionString } from '@/util/executeFunctionString';

export function* iterateSequenceScriptItems(
  script: Partial<SequenceScriptItem>[],
  shiftContext: ShiftContext,
  initialIndex: number = 0
) {
  let currentIndex = initialIndex;

  function getNextIndex(scriptItem: SequenceScriptItem) {
    if (scriptItem.gotoDirect) {
      const tag = processStringWithVars(scriptItem.gotoDirect, shiftContext, '', true);
      const index = script.findIndex(item => item.tag === tag);
      console.log(`🐬 GotoDirect function returned index: ${currentIndex}`);
      if (index > -1) {
        return index;
      } else {
        console.error(`Tag not found: ${tag}`);
      }
    }

    if (scriptItem.goto) {
      const sortedGoto = [...scriptItem.goto].sort((a, b) => {
        if (!a.condition && b.condition) return 1;
        if (a.condition && !b.condition) return -1;
        return 0;
      });

      for (const { condition, tag: rawTag } of sortedGoto) {
        const conditionMet = !condition
          ? true
          : executeFunctionString(condition, createStringyVarsContext(shiftContext));

        console.log(`❓ Checked condition: ${JSON.stringify(condition)} -> ${conditionMet}`);

        if (conditionMet) {
          const tag = processStringWithVars(rawTag, shiftContext, '', true);
          const index = script.findIndex(item => item.tag === tag);
          console.log(`🐬 Goto function returned index: ${currentIndex}`);
          if (index > -1) {
            return index;
          } else {
            console.error(`Tag not found: ${tag}`);
          }
          break;
        }
      }
    }

    return currentIndex + 1;
  }

  while (currentIndex < script.length) {
    const scriptItemPartial = script[currentIndex];
    const scriptItem = deepMerge(defaultScriptItemProperties, scriptItemPartial) as DeepRequired<SequenceScriptItem>;

    if (scriptItem.bypass) {
      currentIndex++;
      continue;
    }

    console.log(`🐬 Executing script item`, scriptItem);

    if (scriptItem.skipIf !== undefined) {
      console.log(`🐬 SkipIf function found: ${scriptItem.skipIf}`);

      const skip = executeFunctionString(scriptItem.skipIf, createStringyVarsContext(shiftContext));
      console.log(`🐬 SkipIf function returned: ${skip}`);

      if (skip) {
        currentIndex++;
        continue;
      }
    }

    yield { scriptItem, scriptItemIndex: currentIndex };

    currentIndex = getNextIndex(scriptItem);
  }
}

type DeepRequired<T> = {
  [P in keyof T]-?: T[P] extends number | string | boolean | undefined | null | symbol ? T[P] : DeepRequired<T[P]>;
};
