import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

export async function loadScriptDataFromFirebase(docId: string) {
  const docRef = doc(db, 'CinematicScripts', docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();

    data.script = JSON.parse(data.script || '[]');
    data.assets = data.assets && JSON.parse(data.assets || '{}');
    return data as {
      id: string;
      name: string;
      assets?: Record<string, string>;
      script: SequenceScript;
    };
  } else {
    alert(`
    Script ${docId} not found!
    Please check the script name and try again!`);
    return null;
  }
}
