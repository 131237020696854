import { Mesh, MeshStandardMaterial, Object3D } from 'three';

type TResult = MeshStandardMaterial;
export function createModelPartMaterialsByNameProxy<T extends { [key: string]: string }>(
  model: Object3D,
  partNames: T
) {
  const result = {} as Record<keyof T, TResult>;

  for (const [key, value] of Object.entries(partNames)) {
    const mesh = model.getObjectByName(value)! as Mesh;
    result[key as keyof T] = mesh.material as TResult;
  }

  return result as Record<keyof T, TResult>;
}
