import { LitElement, css, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';

import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';
import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { getShifterName } from '@/util/getShifterName';

import '@/html/common/GlassButton';
import '@/html/components/DisclaimerSection';
import '@/html/components/PricingCarousel';

import '@/styles/index.scss';

const TAG_NAME = 'subscription-screen';

export const PLAN_CHOSEN_EVENT = 'plan-chosen';

const pricingItemsData = [
  {
    title: 'STARTER',
    description: '30-Day Free Trial',
    ticks: [
      'The Presence Shift Starter Program',
      'New Presence Shift Every Sunday ($199/yr. value)',
      'New Podcast Shift Every Wednesday ($99/yr. value)',
      'Unlimited Mindfulness Shifting ($112/yr. value)',
      'Personalized Email Feedback When You Finish The Presence Shift Starter Program (No Deadline)',
      'New Subscriber Bonus: Subscribing Today Extends Your Subscription Through December 31st, 2025',
    ],
    pricingText: '$99/yr after 30 days free',
    cancellationText: 'No charge if you cancel during your free trial.',
  },
  {
    title: 'STARTER PLUS',
    description: 'The STARTER Subscription\\n+ The Shift Mobile App',
    ticks: [
      'Unlimited personalized Shifts, mindfulness mastery program access, and personal audio diary recording in the iOS or Android Shift mobile app.',
      'The Shift app offers unique Shifts, mastery programs, audio journals, community recordings, and user-created Shifts ($156/yr. value)',
    ],
    pricingText: '$199/yr after 30 days free',
    cancellationText: 'No charge if you cancel during your free trial.',
  },
  {
    title: 'LIVE SHIFT',
    description: 'The STARTER PLUS Subscription\\n+ Live Community Zoom Shifts',
    ticks: ['Live Monthly Community Zoom Shifts with Presence and Dr. Sullivan'],
    cancellationText: 'Coming in 2025',
    disable: true,
  },
];

@customElement(TAG_NAME)
export class SubscriptionScreen extends LitElement {
  @property({ type: Number })
  public activeAccordionIndex: number = -1;

  static styles = css`
    :host {
      display: block;
      background-size: cover;
      color: #fff;
      text-align: center;
      border-radius: 8px;
      position: relative;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
    }
    .abhaya-libre-semibold {
      font-family: "Abhaya Libre", serif;
      font-weight: 600;
      font-style: normal;
      font-size: 60px;
      margin-bottom: 32px;
    }
    .bg-section {
      background: url("/images/background.webp") no-repeat center
        center;
      background-size: cover;
    }
    .container {
      max-width: 1500px;
      margin: 0 auto;
      padding: 20px;
      padding-top: 40px;
      padding-bottom: 324px;
    }
    .border-line {
      height: 8px;
      width: 100%;
      background: #303a35;
    }
    img.logo {
      width: 105px;
      height: 75px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
    .parent-heading {
      margin: 0 auto;
      font-size: 64px;
      font-weight: 600;
      line-height: 1.2em;
      padding-top: 80px;
      max-width:800px;
      letter-spacing: 2px;
      text-align:center;
    }
    .shift-highlight {
      color: #00ffc2;
    }
    p {
      font-size: 1.25em;
    }
    button {
      background: #fff;
      color: #007bff;
      border: none;
      padding: 0.75em 1.5em;
      font-size: 1em;
      font-weight: 500;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 1em;
      margin-bottom: 1.5em;
    }
    button:hover {
      background: #e9ecef;
    }
    .first-text-block {
      margin-top: 70px;
    }
    .additional-gradient-bar {
      height: 10px;
      width: 100px;
      background: linear-gradient(to right, #08feb3 0%, #0f80a8 100%);
      border-radius: 16px;
      margin-bottom: 25px;
      margin-top: 40px;
    }
    .gradient-bar {
      height: 10px;
      width: 100px;
      background: linear-gradient(to right, #08feb3 0%, #0f80a8 100%);
      border-radius: 16px;
      margin-bottom: 16px;
      margin-top: 100px;
    }
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #00ffc2;
      margin-top: 35px;
      letter-spacing: 0.35px;
      margin-bottom: 0px;
      line-height: 1.2em;
      text-align: left;
    }
    .description {
      font-size: 32px;
      font-weight: 300;
      word-spacing: 2px;
      color: #ffff;
      letter-spacing: 0.5px;
      margin-top: 10px;
      line-height: 1.2em;
      text-align: left;
    }
    /* .second-block {
      padding-top: 10px;
    } */
    .text-block {
      max-width: 1277px;
      margin: 0 auto;
    }
    .title-carousel{
      font-family: "Abhaya Libre", serif;
      font-weight: 500;
      font-style: normal;
      font-size: 70px;
      letter-spacing: 0.5px;
      line-height:62.58px;
      margin-bottom: 32px;
      margin-top:114px;
    }
    .gradient-bar-carousel{
      height: 10px;
      width: 100px;
      background: linear-gradient(to right, #08feb3 0%, #0f80a8 100%);
      border-radius: 16px;
      margin:auto;
      margin-bottom: 17px;
      margin-top: 35px;
    }
    .additional-text {
      margin-top: 250px;
      font-size: 22px;
      line-height: 27px;
      color: #fff;
      text-align: left;
    }
    .additional-text p {
      font-size: 22px;
      margin-top: 25px;
      margin-bottom: 12px;
      font-weight: 300;
      letter-spacing: 1.5px;
    }
    .additional-text a {
      font-size: 22px;
      font-weight: 300;
      text-transform: underline;
      color: #fff;
    }

    @media (max-width: 1200px) {
    .container {
    max-width:830px;
    }
    }
  
     @media (max-width: 800px) {
     .additional-gradient-bar{
      margin-top: 0px;
      width:37px;
      height:4.5px;
      margin-bottom:14px;
      }
      .title-carousel{
      margin-bottom:12px;
      }
      .gradient-bar-carousel{
      width:37px;
      height:4.5px;
      margin-top:0;
      margin-bottom:0;
      }
     .parent-heading{
     font-size:16px;
     line-height:23.13px;
     font-weight:600;
      padding-top: 30px;
      padding-bottom: 30px;
     max-width:300px;
     letter-spacing:0px;
     }
     .title{
     font-size: 13px;
     line-height:20.73px;
     font-weight:700;
      margin-top:0px;
     }
     .gradient-bar{
      margin-top:0;
       width:37px;
      height:4.5px;
     }
    .first-text-block {
      margin-top: 20px;
    }
      .description {
      font-size: 13px;
      line-height: 18.73px;
      margin-top:4px;
      word-spacing:1px;
    }
      .additional-text{
      margin-top: 86px !important;
      }
    .additional-text h1 {
    font-size:23px;
    line-height:27.13px;
    font-weight:600;
    margin-bottom:14px;
     }
    .additional-text p {
      font-size: 12px;
      margin-bottom: 0px;
      font-weight: 300;
      letter-spacing: 0.25px;
      line-height:18.52px;
      margin-top:18px;
    }
      .additional-text a {
      font-size: 12px;
    }
    .container {
    padding-bottom:50px;
    }
    .title-carousel{
    font-family: Abhaya Libre SemiBold;
    font-size: 23px;
    font-weight: 500;
    line-height: 27.13px;
    text-align: center;
    margin-top:44px;
    letter-spacing:1.25px;
    }
  `;

  @property({ type: String })
  public shifterName = getShifterName();

  @query('pricing-carousel')
  private pricingCarousel!: HTMLElement;

  protected render() {
    return html`
      <div>
        <div class="bg-section">
          <div class="container">
            <div>
              <img src="/images/logo.svg" alt="Logo" class="logo" />
            </div>
            <h1 class="parent-heading">
              30 days of
              <span class="shift-highlight"
                >Presence<br />
                Shifting</span
              >
              for free, ${this.shifterName}!
            </h1>
            <div class="text-block">
              <div class="first-text-block">
                <div class="gradient-bar"></div>
                <h1 class="title">Your Free Trial Subscription Begins With The Presence Shift Starter Program:</h1>
                <p class="description">
                  You’ll get the perfect start to shifting your state of being in less than 10 minutes anytime.<br />
                  Take your time deciding if you want to continue year round.
                </p>
                <div class="second-block">
                  <h1 class="title">New Subscriber Bonus:</h1>
                  <p class="description">
                    If you subscribe today your subscription will be
                    <strong>valid through Dec 31, 2025!</strong>
                  </p>
                </div>
              </div>
            </div>
            <div class="">
              <h1 class="title-carousel">Choose Your Plan</h1>
              <div class="gradient-bar-carousel"></div>
              <pricing-carousel items=${JSON.stringify(pricingItemsData)}></pricing-carousel>
            </div>

            <!-- New Text Block Section -->
            <div class="text-block">
              <div class="additional-text">
                <h1 class="abhaya-libre-semibold">Subscribe today!</h1>
                <div class="additional-gradient-bar"></div>
                <p>
                  <strong style="font-weight: 600;">
                    For about 27 cents a day you can continue your mindfulness shifting adventure with Presence year
                    round.
                  </strong>
                </p>
                <div class="child-text">
                  <p>
                    After you subscribe, you’ll begin receiving new weekly Presence Shifts to
                    <strong>shift</strong> stress, anxiety, loneliness and frustration with peace, motivation, love,
                    relaxation, sleep and beautiful presence.
                  </p>
                  <p>
                    You’ll also have unlimited access to our Shifts on the web and our mobile apps in your subscription.
                    You’ll always have a perfect Shift waiting for you to read, listen to, watch, or chat with — anytime
                    you need to make a change to your state of mind and body.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-line"></div>
        <disclaimer-section></disclaimer-section>
      </div>
    `;
  }

  protected firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  public waitUntilPlanChosen() {
    const awaitable = createCustomAwaitable<{ title: string }>();

    const onPlanChosen = (event: CustomEvent) => {
      awaitable.resolve(event.detail);
      this.removeEventListener(PLAN_CHOSEN_EVENT, onPlanChosen as EventListener);
    };

    this.addEventListener(PLAN_CHOSEN_EVENT, onPlanChosen as EventListener);

    return awaitable;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: SubscriptionScreen;
  }
}
