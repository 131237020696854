type TrackablePromise<T> = Promise<T> & {
  isResolved: () => boolean;
};

export function trackPromise<T>(promise: Promise<T>): TrackablePromise<T> {
  let isResolved = false;

  const wrappedPromise = promise.then(
    value => {
      isResolved = true;
      return value;
    },
    error => {
      isResolved = true;
      throw error;
    }
  ) as TrackablePromise<T>;

  wrappedPromise.isResolved = () => isResolved;

  // Ensure the methods are bound correctly
  wrappedPromise.then = wrappedPromise.then.bind(wrappedPromise);
  wrappedPromise.catch = wrappedPromise.catch.bind(wrappedPromise);
  wrappedPromise.finally = wrappedPromise.finally.bind(wrappedPromise);

  return wrappedPromise;
}
