import gsap from 'gsap';

import { LitElement, css, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import Showdown from 'showdown';

import { interruptableDelay } from '@/debug/util/interruptableDelay';

const TAG_NAME = 'placeholder-animation-message';

const mdConverter = new Showdown.Converter();

export async function displayPlaceholderAnimationMessage(
  parentElement: HTMLDivElement,
  textContent: string,
  timeOnScreen = 1 + 0.1 * textContent.length
): Promise<void> {
  const splashScreen = document.createElement(TAG_NAME) as PlaceholderAnimationMessage;
  splashScreen.textContent = textContent;
  parentElement.appendChild(splashScreen);

  await gsap.from(splashScreen, { duration: 0.5, opacity: 0 });

  await interruptableDelay(timeOnScreen);

  await gsap.to(splashScreen, { duration: 0.5, opacity: 0 });

  parentElement.removeChild(splashScreen);
}

class PlaceholderAnimationMessage extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      pointer-events: none;
    }

    .container {
      box-sizing: border-box;

      text-align: center;
      padding: 50px;
      max-width: 720px;
      margin: 2vmin;

      background: rgba(0, 0, 0, 0.8);
      border-radius: 20px;
      border: 2px solid rgba(255, 255, 255, 0.2);
    }

    .mini-title {
      color: #fff9;
      font-size: 0.7em;
      font-weight: 400;
      font-family: Inter, 'Helvetica Neue', sans-serif;
      font-style: normal;
      font-optical-sizing: auto;
      margin-bottom: 1em;
    }

    .context {
      color: white;
      font-size: 2em;
      font-family: serif;
      font-optical-sizing: auto;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  `;

  public textContent = '';

  render() {
    const htmlTextContent = mdConverter.makeHtml(this.textContent);
    return html` <div class="container">
      <div class="mini-title">This is just an placeholder for the following animation</div>
      <div class="context">${unsafeHTML(htmlTextContent)}</div>
    </div>`;
  }
}

customElements.define(TAG_NAME, PlaceholderAnimationMessage);
