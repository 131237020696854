import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { appContext } from '@/context/appContext';
import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';

export async function addSceneLayoutElements(parentElement: HTMLElement) {
  const sceneLayout = new SceneLayout();

  if (appContext.urlParams.debug) {
    sceneLayout.classList.add('debug');
  }

  parentElement.appendChild(sceneLayout);

  await sceneLayout.updateComplete;

  trackOrientationAsCSSClass(sceneLayout);

  const elements = {
    vignette: sceneLayout.shadowRoot?.querySelector('scene-vignette')!,
    containerForTheScene: sceneLayout.shadowRoot?.querySelector('scene-container')!,
  };

  if (appContext.urlParams.debug) {
    for (const element of Object.values(elements)) {
      element?.classList.add('debug');
    }
  }

  return {
    ...elements,
    /**
     * Sets the content of the scene container.
     *
     * This method will remove all existing children from the shadow root of the scene container,
     * and then append the provided content to the shadow root.
     *
     * @param content - The content to set as the content of the scene container.
     */
    setSceneContainerContent(content: HTMLElement) {
      const shadowRoot = elements.containerForTheScene.shadowRoot;
      if (!shadowRoot) {
        throw new Error('Container for the scene has no shadow root');
      }

      while (shadowRoot.firstChild) {
        shadowRoot.removeChild(shadowRoot.firstChild);
      }

      shadowRoot.appendChild(content);
    },
  };
}

export type SceneLayoutElements = ReturnType<typeof addSceneLayoutElements>;

@customElement('scene-layout')
class SceneLayout extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }
  `;

  render() {
    return html`
      <scene-container></scene-container>
      <scene-vignette> </scene-vignette>
    `;
  }
}

////////////////////////////////////////////////////////////////////////////////
//// Extra Components
////////////////////////////////////////////////////////////////////////////////

@customElement('scene-container')
class SceneContainer extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      // overflow: hidden;
      // pointer-events: none;
      // display: flex;
      // justify-content: flex-start;
      z-index: -2;
    }
  `;
}

@customElement('scene-vignette')
class Vignette extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('/images/vignette.png');
      background-size: 100% 100%;
      pointer-events: none;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    'scene-layout': SceneLayout;
    'scene-container': SceneContainer;
    'scene-vignette': Vignette;
  }
}
