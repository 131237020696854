export function executeFunctionString(functionString: string, context: Record<string, any>) {
  try {
    const contextKeys = Object.keys(context);
    const contextValues = Object.values(context);
    const func = new Function(...contextKeys, `return ${functionString};`);
    return func(...contextValues);
  } catch (error) {
    console.error('🎤 Error executing function string:', functionString, error);
    return null;
  }
}
