export function waitUntil(cb: () => boolean): Promise<void> {
  return new Promise<void>(resolve => {
    function checkCondition() {
      if (cb()) {
        resolve();
      } else {
        requestAnimationFrame(checkCondition);
      }
    }
    requestAnimationFrame(checkCondition);
  });
}
