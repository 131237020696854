import { LitElement, html, css } from 'lit';

export class AnimatedBackground extends LitElement {
  static tagName = 'animated-background';

  static styles = css`
    :host {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;

      background: url('https://e7.pngegg.com/pngimages/942/683/png-transparent-polka-background-with-small-dots-seamless-pattern.png')
        #326;
      background: url('https://upload.wikimedia.org/wikipedia/en/b/be/Locator_Grid.png') #236;
      background-blend-mode: multiply;
      background-repeat: repeat;

      animation: slidy 1999s linear infinite;

      z-index: -1;
    }

    @keyframes slidy {
      0% {
        background-position: 0 -10000px;
      }
      100% {
        background-position: 10000px 10000px;
      }
    }
  `;
}

customElements.define(AnimatedBackground.tagName, AnimatedBackground);
