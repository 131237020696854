import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { LitElement, css, html } from 'lit-element';
import { customElement, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import Showdown from 'showdown';

const TAG_NAME = 'shifter-input-pick-option';
declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShifterInputPickOption;
  }
}

const mdConverter = new Showdown.Converter();

@customElement(TAG_NAME)
export class ShifterInputPickOption extends LitElement {
  static readonly styles = css`
    :host {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      max-height: 100vh;
      overflow-y: auto;
      padding: 1rem;
    }

    .button-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    button {
      flex: 0 1 auto;
      pointer-events: all;
      cursor: pointer;

      font-size: 1rem;
      padding: 0.0rem 1.5rem;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border: none;
      border-radius: 25px;
      transition:
        color 0.2s,
        opacity 0.2s,
        background-color 0.2s;

      font-family: Inter, sans-serif;
      font-weight: 400;
      text-align: center;
    }

    button:hover {
      color: cyan;
      background-color: rgba(0, 0, 0, 0.6);
    }

    button[disabled] {
      opacity: 0;
      pointer-events: none;
    }
  `;

  @property({ type: Array })
  buttonDescriptors: ButtonDescriptor[] = [];

  public readonly onOptionPicked = createDelegateFunction<[value: unknown]>();

  render() {
    return html`
      <div class="button-container">
        ${this.buttonDescriptors.map(descriptor => {
          const { label, value } = descriptor;
          return html`
            <button @click="${() => this.onOptionPicked.emit(value)}">
              ${unsafeHTML(mdConverter.makeHtml(label))}
            </button>
          `;
        })}
      </div>
    `;
  }
}

interface ButtonDescriptor {
  label: string;
  value: any;
}
