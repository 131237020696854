import { css } from 'lit';

export module ReusableStyles {
  export const glassButton = css`
    button.glass-button {
      font-family: Inter;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      min-width: fit-content;
      display: flex;
      padding: 16px 64px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border: none;
      border-radius: 120px;
      background: linear-gradient(6deg, rgba(0, 0, 0, 0.18) 3.12%, rgba(0, 255, 209, 0.14) 95.05%);
      box-shadow:
        0px 2px 12px 0px rgba(67, 255, 187, 0.72) inset,
        0px 6px 8px 0px rgba(255, 255, 255, 0.12) inset;
      backdrop-filter: blur(15px);

      transition-duration: 0.2s;
      transition-property: color, background, box-shadow;

      pointer-events: all;
      cursor: pointer;
    }

    button.glass-button:not(:disabled) {
      cursor: auto;
    }

    button.glass-button:not(:disabled):hover {
      color: cyan;
      background: linear-gradient(6deg, rgba(0, 0, 0, 0.28) 3.12%, rgba(0, 255, 209, 0.24) 95.05%);
      box-shadow:
        0px 2px 12px 0px rgba(67, 255, 187, 0.92) inset,
        0px 6px 8px 0px rgba(255, 255, 255, 0.22) inset;
    }
  `;
}
