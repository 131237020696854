import gsap from 'gsap';

import { playShiftScript } from '@/animation/playShiftScript';
import { appContext } from './context/appContext';
import { SceneContext } from './context/sceneContext';
import { ShiftContext, createShiftContext } from './context/shiftContext';
import { loadRealOrMockScriptData } from './data/loadRealOrMockScriptData';
import { initializeDevThingsForShift } from './debug/initializeDevThingsForShift';
import { addPlaybackLayoutElements } from './html/addPlaybackLayoutElements';
import { CircularProgressBar } from './html/common/CircularProgressBar';
import { createFastForwardingInteractionManager } from './services/createFastForwardingInteractionManager';
import { trackPromise } from './util/core/trackPromise';

export async function runPlayer(scriptSlug: string, sceneContext: SceneContext) {
  const { appDiv, fastforwarder } = appContext;

  const scriptDataLoadingPromise = trackPromise(
    loadRealOrMockScriptData(scriptSlug, appContext.urlParams.localScripts)
  );

  const elements = await addPlaybackLayoutElements(appDiv);

  const shiftContext = createShiftContext(sceneContext, elements, scriptSlug);

  initializeDevThingsForShift(shiftContext);

  appContext.events.shiftStarted.emit(shiftContext);

  const scriptData = await scriptDataLoadingPromise;
  if (!scriptData) return;
  console.log(
    `📄 Loaded doc "${scriptData.name || scriptData.id}". Script:`,
    scriptData.script,
    'Assets:',
    scriptData.assets
  );

  const progressBar = await addProgressBar(shiftContext, scriptData.script);

  //// Assets loaded, show the scene

  const disposeFastForwardingInteractionManager = createFastForwardingInteractionManager(
    [elements.fastforwardingHitArea],
    fastforwarder
  );

  if (!appContext.urlParams.skipScript) {
    appContext.noSleep.enable();

    await playShiftScript(scriptData.script, shiftContext);

    appContext.noSleep.disable();
  } else {
    await gsap.to(progressBar, { duration: 1.5, progress: 1 });
  }

  console.log('🎬 Script finished!');

  appContext.events.shiftFinished.emit(shiftContext);

  shiftContext.dispose();

  disposeFastForwardingInteractionManager();

  progressBar.remove();
}

export async function addProgressBar(shiftContext: ShiftContext, script: SequenceScript) {
  const { appDiv } = appContext;
  const { events } = shiftContext;

  const progressBar = new CircularProgressBar();
  appDiv.appendChild(progressBar);
  progressBar.radius = 20;
  await progressBar.updateComplete;
  appContext.events.shiftStarted;

  events.playScriptItemFinished.on(async (_, itemIndex) => {
    progressBar.progress = (itemIndex + 1) / script.length;
  });

  return progressBar;
}
