import { BlurryWrapper } from '@/html/common/BlurryWrapper';
import { BoomerangVideoBackground } from '@/html/components/BoomerangVideoBackground';
import { createAnimatedElementVisibilityControl } from '@/html/utils/createAnimatedElementVisibilityControl';

export function createSplashService() {
  const appDiv = document.getElementById('app');
  if (!appDiv) throw new Error('#app element not found');

  const backgroundContainer = document.createElement('div');
  backgroundContainer.id = 'splashScreensBackground';
  backgroundContainer.style.position = 'fixed';
  backgroundContainer.style.top = '0';
  backgroundContainer.style.left = '0';
  backgroundContainer.style.width = '100%';
  backgroundContainer.style.height = '100%';
  // backgroundContainer.style.zIndex = '9998';
  backgroundContainer.style.pointerEvents = 'none';

  const splashScreensContainer = document.createElement('div');
  splashScreensContainer.id = 'splashScreensContainer';
  splashScreensContainer.style.position = 'fixed';
  splashScreensContainer.style.top = '0';
  splashScreensContainer.style.left = '0';
  splashScreensContainer.style.width = '100%';
  splashScreensContainer.style.height = '100%';
  splashScreensContainer.style.zIndex = '9999';
  splashScreensContainer.style.pointerEvents = 'none';

  appDiv.appendChild(backgroundContainer);
  appDiv.appendChild(splashScreensContainer);

  const splashScreens: Set<HTMLElement> = new Set();

  let isBackgroundEnabled = true;

  const FADE_IN_DURATION = 0.7;
  const FADE_OUT_DURATION = 0.3;

  const videoBackground = new BoomerangVideoBackground();
  const videoBackgroundAnimator = createAnimatedElementVisibilityControl(
    videoBackground,
    backgroundContainer,
    FADE_IN_DURATION,
    FADE_OUT_DURATION,
    visibility => (videoBackground.style.opacity = String(visibility))
  );
  const blurryWrapper = new BlurryWrapper();
  const blurryWrapperAnimator = createAnimatedElementVisibilityControl(
    blurryWrapper,
    backgroundContainer,
    FADE_IN_DURATION,
    FADE_OUT_DURATION,
    visibility => (blurryWrapper.visibility = visibility)
  );

  function updateBackground() {
    const anySplashScreens = splashScreens.size > 0;

    splashScreensContainer.style.pointerEvents = anySplashScreens ? 'auto' : 'none';

    const shouldShowBackgroundVideo = anySplashScreens && isBackgroundEnabled;
    videoBackgroundAnimator.setVisible(shouldShowBackgroundVideo);

    const shouldShowBackgroundDimmer = anySplashScreens;
    blurryWrapperAnimator.setVisible(shouldShowBackgroundDimmer);
  }

  Object.assign(window, {
    bgVid: videoBackground,
    bgDim: blurryWrapper,
  });

  // Create a MutationObserver to watch for changes in the splashScreensContainer
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach(node => {
          if (node instanceof HTMLElement) {
            splashScreens.add(node);
          }
        });

        mutation.removedNodes.forEach(node => {
          if (node instanceof HTMLElement) {
            splashScreens.delete(node);
          }
        });

        updateBackground();
      }
    });
  });
  observer.observe(splashScreensContainer, { childList: true });

  return {
    container: splashScreensContainer,

    get splashScreensCount() {
      return splashScreens.size;
    },

    add(element: HTMLElement) {
      splashScreensContainer.appendChild(element);
      return () => {
        this.remove(element);
      };
    },

    remove(element: HTMLElement) {
      splashScreensContainer.removeChild(element);
    },

    setSplashScreensBackgroundEnabled(enabled: boolean) {
      isBackgroundEnabled = enabled;
      updateBackground();
    },
  };
}

export type SplashService = ReturnType<typeof createSplashService>;
