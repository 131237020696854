import {
  DoubleSide,
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  MirroredRepeatWrapping,
  RepeatWrapping,
  TextureLoader,
} from 'three';
import { createVideoTextureFromUrl } from './utils/createVideoTextureFromUrl';

import type { ensureAllAssetsLoaded } from './ensureAllAssetsLoaded';
import { ModelNodeNames } from './constants/ModelNodeNames';
import { createModelPartObjectByNameProxy } from './createModelPartObjectByNameProxy';

type Gltfs = Awaited<ReturnType<typeof ensureAllAssetsLoaded>>;

export function setupAndAddModelsToScene(gltfs: Gltfs, scene: THREE.Scene) {
  for (const gltf of Object.values(gltfs)) {
    scene.add(gltf.scene);
    gltf.scene.visible = false;
  }

  const camera = scene.getObjectByName('CameraHolder')!;
  camera.position.z = 1050;
  camera.position.y = 50;

  const sandMaterial = createAnimatedSandMaterial();

  const nonFoggables = ['FakeLight', 'pPlane2'];

  gltfs.home.scene.position.set(100, -200, 250);
  gltfs.home.scene.traverse(object => {
    if (object instanceof Mesh && object.material instanceof MeshStandardMaterial) {
      const material = object.material;

      if (nonFoggables.every(n => !object.name.includes(n))) {
        // material.fog = false;
        material.alphaTest = 0.5;
        material.transparent = true;
        material.side = DoubleSide;
      }

      if (object.name.includes('Sand')) {
        material.color.set('#27828f');
        object.material = sandMaterial;
      }

      if (material.name === 'aiStandardSurface3') {
        object.material.color.set('#10b6f3');
      }

      if (object.name.includes('FakeLight')) {
        console.log(`
        
        🎨 Found fake light

        object.name: ${object.name}
        material.name: ${material.name}
        material.color: ${material.color}
        
        `);

        //// Render both sides of the light
        material.side = DoubleSide;
      }
    }
  });

  // flipNormalsRecursively(gltfs.penguin.scene);

  gltfs.dolphin1.scene.traverse(object => {
    if (object instanceof Mesh && object.material instanceof MeshStandardMaterial) {
      // object.material.emissive = new Color('#0088ff');
      // object.material.emissiveIntensity = 0.5;
      object.material.fog = true;
    }
  });
  gltfs.dolphin1.scene.position.x = 200;
  gltfs.dolphin1.scene.position.y = 0;
  gltfs.dolphin1.scene.scale.setScalar(1.2);

  gltfs.dolphin2.scene.scale.setScalar(0.5);
  const d1parts = createModelPartObjectByNameProxy(gltfs.dolphin1.scene, ModelNodeNames.Dolphin1);
  const d2parts = createModelPartObjectByNameProxy(gltfs.dolphin2.scene, ModelNodeNames.Dolphin2);
  d2parts.body.material = d1parts.body.material;
  d2parts.eyeL.material = d1parts.eyeL.material;
  d2parts.eyeR.material = d1parts.eyeR.material;
}

function createAnimatedSandMaterial() {
  const textureLoader = new TextureLoader();

  const occUrl = '/textures/home/sand-occlusions.webp';
  const occTexture = textureLoader.load(occUrl);

  const noiseUrl = '/textures/home/sand-detail.webp';
  const noiseTexture = textureLoader.load(noiseUrl);
  noiseTexture.wrapS = RepeatWrapping;
  noiseTexture.wrapT = RepeatWrapping;
  noiseTexture.repeat.set(6, 6);

  const videoTexture = createVideoTextureFromUrl('/video/Caustics.mp4');
  videoTexture.wrapS = MirroredRepeatWrapping;
  videoTexture.wrapT = MirroredRepeatWrapping;
  videoTexture.repeat.set(2, 2);
  videoTexture.offset.set(0.65, 0.15);

  const material = new MeshLambertMaterial({
    map: occTexture,
    aoMap: noiseTexture,
    aoMapIntensity: 2,
    lightMap: videoTexture,
    lightMapIntensity: 7,
  });
  material.color.set('#27828f');

  return material;
}
