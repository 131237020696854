import { Group, Material, Mesh, Object3D } from 'three';

export function createThreejsObjectTweenableWrapper(obj: Group | Mesh) {
  const firstMaterial = findFirstMaterialRecursively(obj);
  const allMaterials = findAllMaterialsRecursively(obj);

  return {
    get scaleAll() {
      return obj.scale.x;
    },
    set scaleAll(value: number) {
      obj.scale.set(value, value, value);
    },

    get scaleX() {
      return obj.scale.x;
    },
    set scaleX(value: number) {
      obj.scale.x = value;
    },
    get scaleY() {
      return obj.scale.y;
    },
    set scaleY(value: number) {
      obj.scale.y = value;
    },
    get scaleZ() {
      return obj.scale.z;
    },
    set scaleZ(value: number) {
      obj.scale.z = value;
    },

    get positionX() {
      return obj.position.x;
    },
    set positionX(value: number) {
      obj.position.x = value;
    },
    get positionY() {
      return obj.position.y;
    },
    set positionY(value: number) {
      obj.position.y = value;
    },
    get positionZ() {
      return obj.position.z;
    },
    set positionZ(value: number) {
      obj.position.z = value;
    },

    get rotationX() {
      return obj.rotation.x;
    },
    set rotationX(value: number) {
      obj.rotation.x = value;
    },
    get rotationY() {
      return obj.rotation.y;
    },
    set rotationY(value: number) {
      obj.rotation.y = value;
    },
    get rotationZ() {
      return obj.rotation.z;
    },
    set rotationZ(value: number) {
      obj.rotation.z = value;
    },

    get visible() {
      return obj.visible;
    },
    set visible(value: boolean) {
      obj.visible = value;
    },

    get opacity() {
      if (!firstMaterial) return NaN;
      return firstMaterial.opacity ?? 1;
    },
    set opacity(value: number) {
      for (const material of allMaterials) {
        material.transparent = true;
        material.opacity = value;
      }
    },
  };
}

function findFirstMaterialRecursively(obj: Object3D | Mesh): Material | null {
  if ('material' in obj) {
    const material = Array.isArray(obj.material) ? obj.material[0] : obj.material;
    return material;
  }

  for (const child of obj.children) {
    const material = findFirstMaterialRecursively(child);
    if (material) {
      return material;
    }
  }

  return null;
}

function findAllMaterialsRecursively(obj: Object3D | Mesh): Material[] {
  if ('material' in obj) {
    const material = Array.isArray(obj.material) ? obj.material : [obj.material];
    return material;
  }

  const materials: Material[] = [];

  for (const child of obj.children) {
    const childMaterials = findAllMaterialsRecursively(child);
    materials.push(...childMaterials);
  }

  return materials;
}
