import { appContext } from '@/context/appContext';
import { ShiftContext } from '@/context/shiftContext';

import { createCharacterAnimationController } from '@/controllers/createCharacterAnimationController';
import { ModelNodeNames } from '@/scene/constants/ModelNodeNames';
import { createModelPartObjectByNameProxy } from '@/scene/createModelPartObjectByNameProxy';

export async function initializeDevThingsForShift(shiftContext: ShiftContext) {
  const { gltfs } = shiftContext;
  // const { addEnterFrameCallback } = shiftContext.sceneContext! || {};

  if (appContext.urlParams.ffSpeed !== undefined) {
    console.log('🔄 Fast forward speed:', appContext.urlParams.ffSpeed);
    appContext.fastforwarder.isFastForwarding.onChange.on(isFastForwarding => {
      const { playerCtrl } = shiftContext;
      const REASON_TO_SKIP = 'fast-forward-in-progress';
      playerCtrl.reasonsToSkipWaitingForUserTap.set(REASON_TO_SKIP, isFastForwarding);
      playerCtrl.reasonsToSkipWaitingForUserInput.set(REASON_TO_SKIP, isFastForwarding);

      console.log('🔄 Fast forwarding:', isFastForwarding);
    });
  }

  Object.assign(globalThis, {
    shiftContext,
    d1: {
      scene: gltfs!.dolphin1.scene,
      animations: gltfs!.dolphin1.animations,
      parts: createModelPartObjectByNameProxy(gltfs!.dolphin1.scene, ModelNodeNames.Dolphin1),
      // ctrl: createCharacterAnimationController(gltfs!.dolphin1, addEnterFrameCallback),
    },
    d2: {
      scene: gltfs!.dolphin2.scene,
      animations: gltfs!.dolphin2.animations,
      parts: createModelPartObjectByNameProxy(gltfs!.dolphin2.scene, ModelNodeNames.Dolphin2),
      // ctrl: createCharacterAnimationController(gltfs!.dolphin2, addEnterFrameCallback),
    },
    gltfs,
    sceneCtrl: shiftContext.sceneContext?.sceneCtrl,
  });

  let endIndex = 0;
  //// Ultra-fast-forward from browser console
  Object.assign(globalThis, {
    ffTo: (
      target: any //
    ) => ultraFastForward_smart(target, shiftContext, endIndex),
  });
  {
    shiftContext.events.playScriptStarted.on(script => {
      endIndex = script.length;

      console.log(`🕷 Playing script ${shiftContext.scriptSlug} with ${endIndex} items`);

      //// Ultra-fast-forward to script item index
      const ultraFastForwardingTarget = appContext.urlParams.ffTo;
      if (ultraFastForwardingTarget !== undefined) {
        ultraFastForward_smart(ultraFastForwardingTarget, shiftContext, endIndex);
      }
    });
  }
}

const isNumber = (target: number | string): target is number => !Number.isNaN(Number(target));

function ultraFastForward_smart(target: number | string, shiftContext: ShiftContext, endIndex: number) {
  const isTag = !isNumber(target);
  if (isTag) {
    const targetTag = String(target);
    const isTargetReached = (scriptItem: SequenceScriptItem) => {
      return scriptItem.tag === targetTag;
    };
    ultraFastForward(shiftContext, isTargetReached);
  } else {
    const targetIndex = target <= 1 ? Math.floor(target * endIndex) : target;
    if (isNaN(targetIndex)) return;
    if (targetIndex <= 0) return;
    const isTargetReached = (_: SequenceScriptItem, scriptItemIndex: number) => {
      return scriptItemIndex >= targetIndex;
    };
    ultraFastForward(shiftContext, isTargetReached);
  }
}

function ultraFastForward(
  shiftContext: ShiftContext,
  isTargetReached: (scriptItem: SequenceScriptItem, scriptItemIndex: number) => boolean
) {
  console.log(`🐬 Ultra-fast-forwarding START`);

  const { playerCtrl, events } = shiftContext;

  const REASON_TO_SKIP = 'ultra-fast-forward-in-progress';
  const stopSkippingTTC = playerCtrl.reasonsToSkipWaitingForUserTap.add(REASON_TO_SKIP);
  const stopSkippingINPUT = playerCtrl.reasonsToSkipWaitingForUserInput.add(REASON_TO_SKIP);
  const stopMutingVoice = appContext.voice.reasonsToMute.add(REASON_TO_SKIP);
  const stopMutingAIVoice = appContext.aiVoice.reasonsToMute.add(REASON_TO_SKIP);
  const stopMutingSFX = appContext.sfx.reasonsToMute.add(REASON_TO_SKIP);

  const SUPER_FF_SPEED = 99;
  const timeScaleMultiplier = appContext.animCtrl.timeScale.addMultiplier(SUPER_FF_SPEED);

  const offScriptItemStarted = events.playScriptItemStarted.on((scriptItem, scriptItemIndex) => {
    const keepGoing = !isTargetReached(scriptItem, scriptItemIndex);
    if (keepGoing) return;

    console.log(`🐬 Stopping fast forwarding at script item index: ${scriptItemIndex}`);

    timeScaleMultiplier.remove();

    stopSkippingTTC();
    stopSkippingINPUT();
    stopMutingVoice();
    stopMutingAIVoice();
    stopMutingSFX();

    offScriptItemStarted();
  });
}
