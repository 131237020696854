import { LitElement, css, html } from 'lit';

import { appContext } from '@/context/appContext';
import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';

import './playback/FastforwardHitArea';

const TAG_NAME = 'playback-layout';

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: PlaybackLayout;
  }
}

class PlaybackLayout extends LitElement {
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: center;
    }

    #gui-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: grid;
      grid-template-rows: 1fr auto;
      align-items: center;
    }

    #main-row {
      width: calc(100% - 20px);
      height: 100%;
      margin: 10px;

      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;

      overflow: hidden;
    }

    #chat-bubbles-container {
      --margin: 5px;
      margin: var(--margin);

      width: calc(50% - var(--margin) * 2);
      height: calc(100% - var(--margin) * 2);

      transition: width 0.2s ease-in-out;

      position: relative;

      overflow-y: auto;
      overflow-x: hidden;
      pointer-events: all;

      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    :host(.portrait) #chat-bubbles-container {
      width: calc(100% - var(--margin) * 2);
    }

    #chat-bubbles-view {
      --margin: 5px;
      margin: var(--margin);

      width: calc(100% - var(--margin) * 2);
      min-height: calc(100% - var(--margin) * 2);

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      pointer-events: all;
    }

    #chat-bubbles-view > * {
      flex-shrink: 0;
    }

    #input-row {
      width: calc(100% - 20px);
      margin: 10px;

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: calc(100px + 10vmin);
    }

    #user-input-container {
      --margin: 5px;

      margin: var(--margin) 0;

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: calc(100% - var(--margin) * 2);
      max-width: 720px;
      height: 100%;
    }

    :host(.debug) * {
      outline: 2px dashed #0ff2;
    }
    :host(.debug) > * * {
      outline: 2px dashed #0ff6;
      background-color: #f001;
    }
    :host(.debug) #main-row {
      background-color: #00f1;
    }
    :host(.debug) #user-input-row,
    :host(.debug) #user-input-container {
      background-color: #0ff1;
    }
  `;

  render() {
    return html`
      <div id="gui-container">
        <div id="main-row">
          <div id="chat-bubbles-container">
            <div id="chat-bubbles-view"></div>
          </div>
        </div>
        <div id="input-row">
          <div id="user-input-container"></div>
        </div>
        <fastforward-hit-area id="ff-hit-area" class="debug"></fastforward-hit-area>
      </div>
    `;
  }
}

customElements.define(TAG_NAME, PlaybackLayout);

export async function addPlaybackLayoutElements(parentElement: HTMLElement) {
  const playbackLayout = document.createElement(TAG_NAME);

  if (appContext.urlParams.debug) {
    playbackLayout.classList.add('debug');
  }

  parentElement.appendChild(playbackLayout);

  await playbackLayout.updateComplete;

  trackOrientationAsCSSClass(playbackLayout);

  const elements = {
    playbackLayoutRoot: playbackLayout,
    mainRow: playbackLayout.shadowRoot?.querySelector('#main-row')!,
    inputRow: playbackLayout.shadowRoot?.querySelector('#input-row')!,
    containerForChatBubbles: playbackLayout.shadowRoot?.querySelector('#chat-bubbles-view')!,
    containerForShifterInput: playbackLayout.shadowRoot?.querySelector('#user-input-container')!,
    fastforwardingHitArea: playbackLayout.shadowRoot?.querySelector('#ff-hit-area')!,
  };

  if (appContext.urlParams.debug) {
    for (const element of Object.values(elements)) {
      element?.classList.add('debug');
    }
  }

  return elements;
}

export type PlaybackLayoutElements = Awaited<ReturnType<typeof addPlaybackLayoutElements>>;
