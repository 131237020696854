export function transformUrl(withPageReload: boolean) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // Convert hash to a query-like string
  const hashQuery = url.hash.startsWith('#') ? url.hash.slice(1) : url.hash;
  const hashParams = new URLSearchParams(hashQuery);

  // Check for path directly in the hash (e.g., #play/foo)
  const hashDirectPathMatch = url.hash.match(/^#\/?(sign-in|subscribe|play)\/([^/?#]+)/);

  // Alias map for different variations
  const aliasMap: Record<string, string> = {
    signIn: 'sign-in',
    signin: 'sign-in',
    'sign-in': 'sign-in',
    subscribe: 'subscribe',
    subscription: 'subscribe',
    play: 'play'
  };

  let pathAction: string | null = null;
  let pathValue: string | null = null;

  if (hashDirectPathMatch) {
    // If the hash contains a direct path, use it directly
    pathAction = hashDirectPathMatch[1];
    pathValue = hashDirectPathMatch[2];
  } else {
    // Continue with alias map logic
    for (const [aliasKey, canonicalKey] of Object.entries(aliasMap)) {
      if (hashParams.has(aliasKey)) {
        pathAction = canonicalKey;
        pathValue = hashParams.get(aliasKey) || '';
        hashParams.delete(aliasKey);
        break;
      } else if (searchParams.has(aliasKey)) {
        pathAction = canonicalKey;
        pathValue = searchParams.get(aliasKey) || '';
        searchParams.delete(aliasKey);
        break;
      }
    }
  }

  if (pathAction && pathValue !== null) {
    const newPathname = `/${pathAction}/${pathValue}`;
    url.pathname = newPathname;

    // Reconstruct the hash with remaining params, if any
    url.hash = hashParams.toString() ? '#' + hashParams.toString() : '';

    // Construct the new search string
    url.search = searchParams.toString();

    if (withPageReload) {
      window.location.href = url.toString();
    } else {
      window.history.pushState({}, '', url.toString());
    }
  }
}
