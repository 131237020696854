export class AwaitablesTracker {
  private awaitables: Map<string, Promise<any>> = new Map();

  /**
   * Adds an awaitable (Promise) with a corresponding key for debugging purposes.
   * @param awaitable The awaitable (Promise) to be added.
   * @param key The key associated with the awaitable for identification.
   */
  push(awaitable: Promise<any>, key: string): void {
    this.awaitables.set(key, awaitable);
    awaitable.then(() => this.awaitables.delete(key));
  }

  /**
   * Awaits all currently stored awaitables and clears the list afterwards.
   */
  async awaitAll(): Promise<void> {
    await Promise.allSettled(this.awaitables.values());
    this.awaitables.clear();
  }

  /**
   * Clears the list of currently stored awaitables.
   */
  clear(): void {
    this.awaitables.clear();
  }

  /**
   * Returns a string representation of the current awaitables' keys.
   * @returns A string containing all keys, formatted as [key1,key2,...].
   */
  toString(): string {
    return `[${Array.from(this.awaitables.keys()).join(',')}]`;
  }
}
