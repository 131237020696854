import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

const TAG_NAME = 'wip-disclaimer';

@customElement(TAG_NAME)
export class WIPDisclaimer extends LitElement {
  static styles = css`
    :host {
      display: block;
      text-align: center;
      font-family: monospace;
      font-weight: bold;
      font-size: 0.8em;
      color: black;
      background-color: yellow;
      padding: 0.1em 1em;
      margin: 0.5em;
      border-radius: 3px;
    }
  `;

  render() {
    return html` <slot>Work in progress.</slot> `;
  }
}
