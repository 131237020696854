import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/backend/firebase';
import { ensureUserAuthenticated } from '@/backend/ensureUserSignedIn';
import { appContext } from '@/context/appContext';
import { nextFrame } from '@/util/core/nextFrame';

const liveSubscriptionKeys = ['subStarter', 'subStarterPlus'];
const testSubscriptionKeys = ['subStarter_test', 'subStarterPlus_test'];

export async function getIsUserOnAnySubscriptionPlan(includingTestItems: boolean = false): Promise<boolean> {
  await nextFrame();
  
  if (appContext.urlParams.fakePay) {
    return true;
  }

  try {
    const userId = await ensureUserAuthenticated();

    const docRef = doc(db, 'UserPurchases', userId);
    const purchases = await getDoc(docRef);

    if (!purchases.exists()) {
      return false;
    }

    const purchasesData = purchases.data();
    if (!purchasesData) {
      return false;
    }

    const keysToCheck = includingTestItems ? [...liveSubscriptionKeys, ...testSubscriptionKeys] : liveSubscriptionKeys;

    return keysToCheck.some(key => Boolean(purchasesData[key]));
  } catch (error) {
    console.error('Error checking user subscription:', error);
    return false;
  }
}
