export function during(promise: Promise<any>, fn: () => unknown) {
  let rafId: number;

  // Function to loop every animation frame
  function rafLoop() {
    rafId = requestAnimationFrame(rafLoop);
    fn();
  }

  // Start the loop
  rafLoop();

  // Once the promise settles, cancel the animation frame loop and clean up
  return promise
    .then(() => {
      cancelAnimationFrame(rafId);
    })
    .catch(() => {
      cancelAnimationFrame(rafId);
    });
}
