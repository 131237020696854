import { ShiftContext } from '@/context/shiftContext';
import { createFlatStringyVarsContext } from '@/services/createStringyVarsContext';

/**
 * Processes given string, by replacing {{special-key}} needles with corresponding values
 * from a user answers store.
 */
export function processStringWithVars(
  text: string,
  shiftContext: ShiftContext,
  fallbackValue: string,
  ignoreAnswerDefaults: boolean
) {
  const vars = createFlatStringyVarsContext(shiftContext, ignoreAnswerDefaults);

  console.log('🧠 Processing string:', text, vars);

  return text.replace(/{{([\w\.]+)}}/g, (_, key) => String(vars[key] ?? fallbackValue));
}
