export const defaultScriptItemProperties = {
  message: '',
  messageStyle: 'default-bubble',
  origin: [0.5, 0.9] as [number, number],
  in: {
    stagger: false,
    scale: 0.0,
    easing: 'power3.out',
    duration: 0.4,
  },
  out: {
    stagger: false,
    scale: 1.0,
    easing: 'power1.in',
    duration: 0.25,
  },
  timeOnScreen: 0.0,
} satisfies SequenceScriptItem;
