import { gsap } from 'gsap';

const imageUrl = '/images/breathe-on.png';

export async function displayBreathingAnimation(
  parent: HTMLElement,
  durationBreatheIn: number = 1.2,
  durationBreatheOut: number = 1.2
): Promise<void> {
  // Create a new wrapper element
  const wrapper = document.createElement('div');
  wrapper.style.position = 'fixed';
  wrapper.style.top = '0';
  wrapper.style.left = '0';
  wrapper.style.width = '100%';
  wrapper.style.height = '100%';
  wrapper.style.display = 'grid';
  wrapper.style.justifyItems = 'center';
  wrapper.style.alignItems = 'center';
  wrapper.style.pointerEvents = 'none';

  // Create a new image element
  const img = document.createElement('img');
  img.src = imageUrl;
  img.style.opacity = '0';

  // Append the image to the wrapper element
  wrapper.appendChild(img);

  // Append the wrapper to the parent element
  parent.appendChild(wrapper);

  // Animate the image with GSAP
  await gsap.to(img, {
    scale: 2, // make the image grow
    opacity: 1,
    duration: durationBreatheIn, // duration of the growth
    ease: 'power1.out',
  });

  await gsap.to(img, {
    scale: 0, // make the image shrink
    opacity: 0, // make the image fade away
    duration: durationBreatheOut, // duration of the shrinking and fading
    ease: 'power1.in',
  });

  // After the animation, remove the wrapper from the parent element
  parent.removeChild(wrapper);
}
