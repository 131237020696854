export function isMacOS() {
  // return (
  //   navigator.platform.toUpperCase().indexOf('MAC') >= 0 &&
  //   !navigator.userAgent.match(/(iPad|iPhone|iPod)/i)
  // );

  const userAgent = navigator.userAgent.toLowerCase();

  // Check for Mac OS
  const isMac = /mac/.test(userAgent);

  // Exclude iOS and iPadOS
  const isIOS = /iphone|ipad|ipod/.test(userAgent);

  // Exclude other desktop operating systems
  const isWindows = /win/.test(userAgent);
  const isLinux = /linux/.test(userAgent);

  return isMac && !isIOS && !isWindows && !isLinux;
}
