import { LitElement, css } from 'lit';
import { html } from 'lit-html';

import gsap from 'gsap';

const TAG_NAME = 'shifter-input-text-field';

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ShifterInputTextField;
  }
}

export class ShifterInputTextField extends LitElement {
  static styles = css`
    :host {
      display: block;
      position: relative;
      width: 99%;
      height: 99%;
      max-width: 500px;
      pointer-events: all;
    }

    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #user-text-input-inner {
      height: 3.5rem;

      border-radius: 40px;
      width: 100%;

      box-shadow:
        0 5px 10px rgba(0, 0, 0, 0.5),
        inset 0 0 15px rgba(93, 172, 33, 0.3);
      backdrop-filter: blur(10px);
      color: white;
      font-family: Arial, sans-serif;
      border: 2px solid rgba(78, 210, 166, 0.313);
      background: linear-gradient(45deg, rgba(53, 86, 46, 0.4), rgba(47, 103, 112, 0.4));
      font-size: 24px;

      overflow: hidden;
      animation: unsqueeze 0.5s ease-in-out;
      transition: all 0.2s ease-in-out;

      display: flex;
      grid-template-columns: auto 1fr auto;
      justify-items: center;
      align-items: center;
    }

    #user-text-input-inner > * {
      height: 100%;
      min-width: 10px;
    }

    #user-text-input-inner div.btn-mic,
    #user-text-input-inner div.btn-send {
      // max-width: 30%;
      // width: 50px;
      // height: 50px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #user-text-input-inner button {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
      width: 100%;
      background-color: transparent;
      color: white;
      border: none;
      border-radius: 0 20px 20px 0;
      cursor: pointer;

      fill: #fff5;
      transition: fill 1s;
    }

    #user-text-input-inner button:hover {
      fill: #9ffb;
      transition: fill 0.2s;
    }

    #user-text-input-inner .user-input {
      flex: 1;
    }

    #user-text-input-inner input {
      flex: 1;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-radius: 20px 0 0 20px;
      background: transparent;
      color: white;
      font-size: 2rem;

      animation: fadeIn 0.7s ease-in-out;

      opacity: 0.5;
    }

    input:active,
    input:focus {
      outline: none;
      opacity: 1;
    }

    :host(.listening) #user-text-input-inner > * {
      display: none;
    }
    :host(.listening) #user-text-input-inner {
      box-shadow:
        0 5px 10px rgba(0, 0, 0, 0.5),
        inset 0 0 15px rgba(33, 155, 172, 0.3);
      background: linear-gradient(45deg, rgba(53, 86, 126, 0.5), rgba(47, 103, 152, 0.4));
      border: 2px solid rgba(166, 210, 255, 0.313);
      width: 50%;
      animation: pulse 2s infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }

    @keyframes unsqueeze {
      0% {
        opacity: 0;
        width: 10%;
      }
      100% {
        opacity: 1;
        width: 100%;
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    :host(.debug) #user-text-input-inner > * {
      border: 1px dotted gold;
    }
  `;

  get microphoneButton() {
    return this.getElementByQuery<HTMLButtonElement>('#microphone-button');
  }

  get submitButton() {
    return this.getElementByQuery<HTMLButtonElement>('#submit-button');
  }

  get textInputField() {
    return this.getElementByQuery<HTMLInputElement>('#user-input');
  }

  private getElementByQuery<T extends Element>(selector: string): T {
    if (!this.shadowRoot) throw new Error('No shadow root');

    const result = this.shadowRoot.querySelector(selector);
    if (!result) throw new Error(`No element found with selector: ${selector}`);

    return result as T;
  }

  setListeningMode(listening: boolean) {
    if (listening) {
      this.classList.add('listening');
    } else {
      this.classList.remove('listening');
    }
  }

  render() {
    return html`
      <div id="user-text-input-inner">
        <div class="btn-mic">
          <button id="microphone-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" />
            </svg>
          </button>
        </div>
        <div class="input-field">
          <input type="text" id="user-input" autocomplete="off" />
        </div>
        <div class="btn-send">
          <button id="submit-button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"
              />
            </svg>
          </button>
        </div>
      </div>
    `;
  }

  removeWithAnimation(animationDuration: number) {
    this.blur();

    this.style.pointerEvents = 'none';

    return gsap.to(this, {
      duration: animationDuration,
      opacity: 0,
      scale: 1.1,
      onComplete: () => this.remove(),
    });
  }
}

customElements.define(TAG_NAME, ShifterInputTextField);
