import { appContext } from '@/context/appContext';
import { addSettingsButtonAndDialog } from '@/html/addSettingsButtonAndDialog';

export function showSettingsButtonSometime() {
  const { appDiv, events } = appContext;

  const settingsElements = addSettingsButtonAndDialog(appDiv);

  const scriptItemTagToShowMenuButtonAt = 'got_shifter_name';
  const onSettingsButtonShownCallbacks = [] as (() => unknown)[];
  const onSettingsButtonShown = () => onSettingsButtonShownCallbacks.forEach(cb => cb());

  const showSettingsButton = () => {
    settingsElements.fadeIn(4);
    onSettingsButtonShown();
  };

  const stopListentingForShiftStarted = events.shiftStarted.on(shiftContext => {
    const stopListentingForPlaybackStarted = shiftContext.events.playScriptStarted.on(script => {
      const scriptHasTagAtAll = script.some(item => item.tag === scriptItemTagToShowMenuButtonAt);

      if (!scriptHasTagAtAll) {
        showSettingsButton();
      } else {
        const stopListeningForScriptItemStarted = shiftContext.events.playScriptItemStarted.on(({ tag }) => {
          if (tag === scriptItemTagToShowMenuButtonAt) {
            showSettingsButton();
          }
        });
        onSettingsButtonShownCallbacks.push(stopListeningForScriptItemStarted);
      }
    });
    onSettingsButtonShownCallbacks.push(stopListentingForPlaybackStarted);
  });
  onSettingsButtonShownCallbacks.push(stopListentingForShiftStarted);
}
