import gsap from 'gsap';

export function interruptableDelay(timeInSeconds: number): Promise<void> {
  return new Promise(resolve => {
    // Set a gsap delay that resolves after the specified delay
    const delayedCall = gsap.delayedCall(timeInSeconds, resolve);

    // Set up a click event listener that clears the gsap delay and resolves the promise
    const onClick = () => {
      window.removeEventListener('click', onClick);

      delayedCall.kill();

      resolve();
    };

    window.addEventListener('click', onClick);
  });
}
