import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';

export function createSpeechToTextService() {
  const isSupported = speechToTextSupported();

  return {
    isSupported,
    startSpeechToText: () => {
      const customAwaitable = createCustomAwaitable<string>();

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        customAwaitable.reject('Speech recognition API not supported in this browser.');
        return null;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = 'en-US';

      recognition.onresult = event => {
        console.log('Recognition result:', event.results);
        const transcript = event.results[0][0].transcript;
        customAwaitable.resolve(transcript);
      };

      recognition.onerror = event => {
        customAwaitable.reject(`Error occurred in recognition: ${event.error}`);
      };

      recognition.start();

      return Object.assign(customAwaitable, {
        stop: () => recognition.stop(),
        abort: () => recognition.abort(),
      });
    },
  };
}

function speechToTextSupported() {
  return 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
}

declare global {
  interface SpeechRecognitionErrorEvent {
    error: string;
    message: string;
  }

  interface SpeechRecognitionEvent {
    results: SpeechRecognitionResult[];
  }

  interface SpeechRecognition {
    lang: string;
    onresult: (event: SpeechRecognitionEvent) => void;
    onerror: (event: SpeechRecognitionErrorEvent) => void;
    start(): void;
    stop(): void;
    abort(): void;
  }

  interface Window {
    SpeechRecognition: new () => SpeechRecognition;
    webkitSpeechRecognition: new () => SpeechRecognition;
  }
}
