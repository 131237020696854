import { appContext } from '@/context/appContext';

const matchingWords = ['subscribe', 'subscription'];

export function getShouldShowSubscriptionScreenFlag() {
  if (appContext.urlParams.subscribe) {
    return true;
  }

  const cleanPath = window.location.pathname.replace(/\/+/g, '').replace(/\/$/, '');
  if (matchingWords.some(word => cleanPath === word)) {
    return true;
  }

  const cleanHash = window.location.hash.replace(/#+/g, '#').replace(/^#+/, '');
  if (matchingWords.some(word => cleanHash === word)) {
    return true;
  }

  return false;
}
