import { LitElement, html, css } from 'lit';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { customElement, property } from 'lit/decorators.js';

import { PLAN_CHOSEN_EVENT } from '@/html/components/SubscriptionScreen';

interface CarouselItem {
  title: string;
  description: string;
  ticks?: string[];
  pricingText?: string;
  cancellationText?: string;
  disable?: boolean;
}

const TAG_NAME = 'pricing-carousel';

@customElement(TAG_NAME)
export class PricingCarousel extends LitElement {
  static readonly tagName = TAG_NAME;

  @property({ type: Array })
  items: CarouselItem[] = [];

  @property({ type: Number })
  activeIndex: number = 0;

  static styles = css`
    :host {
      display: block;
      max-width: 1218px;
      margin: 0 auto;
      position: relative;
      padding: 20px;
    }

    .pricing-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .pricing {
      min-height: 1em;
      margin-bottom: 8px;
    }

    .cancellation {
    }

    .highlight-f {
      display: inline-block;
      line-height: 1;
      position: relative;
      overflow: hidden;
      height: 17px;
      width: 10px;
    }

    .carousel-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    }

    .carousel-container {
      display: flex;
      gap: 36px;
      transition: transform 0.5s ease-in-out;
      width: calc(370px * 3 + 36px * 2);
    }

    .carousel-item {
      width: 370px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.5);
      border: 1px solid #00ffc2;
      border-radius: 24px;
      overflow: hidden;
      color: white;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .carousel-text {
      padding: 20px;
    }

    .carousel-item h2 {
      font-size: 30px;
      font-weight: 700;
      margin: -6px;
      text-align: center;
      white-space: pre-line;
    }

    .subscription-text {
      background-color: #0b2a3c;
      color: #00ffc2;
      padding: 9px;
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      display: inline-block;
      border-radius: 0px;
    }

    .carousel-item p {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: -10px;
      line-height: 24px;
      font-weight: 800;
      white-space: pre-line;
    }

    .italic {
      font-style: italic;
      padding-top: 11px;
      min-height: 83px;
    }
    .italic-item {
      font-style: italic;
      padding-top: 11px;
    }
    .normal-text {
      font-style: normal;
      padding-top: 11px;
      min-height: 83px;
    }

    .tick-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-top: 12px;
      text-align: left;
      min-height: 150px;
    }

    .tick-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: left;
      line-height: 16px;
    }

    .tick-content {
      display: flex;
      align-items: start;
      gap: 8.5px;
      padding: 13px 0;
    }

    .tick-text {
      font-size: 12px;
      color: lightgray;
    }

    .styled-text {
      color: #00ffc2;
    }

    .bold-text {
      font-weight: bold;
      color: white;
    }

    .tick-item hr {
      width: 100%;
      border: none;
      border-top: 1px solid #00ffc2;
      margin: 0;
    }

    .pricing-container {
      margin-top: auto;
      text-align: left;
      background: rgba(2, 42, 116, 0.2);
      padding: 10px 20px;
      min-height: 200px;
      overflow: hidden;
    }

    .carousel-item .pricing {
      margin-top: 20px;
      font-size: 24px;
      line-height: 29.05px;
      color: white;
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: 0.4px;
    }

    .carousel-item .cancellation {
      margin-top: 2px;
      font-size: 18px;
      font-weight: bold;
      color: rgba(8, 254, 179, 1);
      min-height: 60px;
      padding-right: 20px;
    }

    .carousel-item button {
      background: linear-gradient(6.27deg, rgba(0, 0, 0, 0.1792) 3.12%, rgba(0, 255, 209, 0.1408) 95.05%);
      color: white;
      padding: 16px 64px;
      border: 2px solid rgba(67, 255, 187, 0.72);
      box-shadow: 0px 6px 8px 0px rgba(255, 255, 255, 0.12) inset;
      box-shadow: 0px 2px 12px 0px rgba(67, 255, 187, 0.72) inset;
      border-radius: 30px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 600;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
      transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
      margin: 20px 0px 38px 12px;
      width: 305px;
    }

    .carousel-item button:hover {
      transform: translateY(-3px);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    }

    .carousel-controls {
      position: absolute;
      top: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);
      pointer-events: none;
      z-index: 10;
    }

    .carousel-controls button {
      background: transparent;
      border: none;
      cursor: pointer;
      pointer-events: all;
      padding: 0;
    }

    .carousel-controls img {
      width: 50px;
      height: 50px;
    }

    .carousel-controls .prev {
      margin-left: -100px;
    }

    .carousel-controls .next {
      margin-right: -60px;
    }

    .dot-container {
      text-align: center;
      position: absolute;
      bottom: -100px;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      border: 1px solid white;
    }

    .dot.active {
      background-color: #00ffc2;
    }

    @media screen and (min-width: 1350px) and (max-width: 1420px) {
      .carousel-controls .prev {
        margin-left: -43px;
      }
      .carousel-controls .next {
        margin-right: -4px;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1350px) {
      .carousel-controls .prev {
        margin-left: -23px;
      }
      .carousel-controls .next {
        margin-right: 14px;
      }
    }

    @media (max-width: 1200px) {
      .carousel-wrapper {
        justify-content: flex-start;
      }
      .carousel-controls {
        top: 100%;
        justify-content: center;
        gap: 10px;
        bottom: 10px;
      }
      .dot-container {
        margin-left: -20px;
      }
    }

    @media (max-width: 800px) {
      .italic {
        min-height: 0px;
      }
      .normal-text {
        min-height: 0px;
      }
      :host {
        padding: 20px 0;
      }
      .carousel-controls {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        pointer-events: none;
        z-index: 10;
      }
      .highlight-f {
        display: inline-block;
        line-height: 1;
        position: relative;
        overflow: hidden;
        height: 10px;
        width: 5px;
      }
      .dot-container {
        margin-left: 0px;
        bottom: -40px;
      }
      .dot {
        width: 5px;
        height: 5px;
      }
      .carousel-item h2 {
        font-size: 15px;
      }
      .subscription-text {
        font-size: 10px;
        margin-top: 4px;
      }
      .carousel-container {
        width: 230px;
        margin: 0 auto;
        gap: 100px;
      }
      .carousel-item {
        width: 100%;
        border-radius: 12px;
      }
      .carousel-item p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
      }
      .carousel-controls .prev {
        margin-left: -10px;
      }
      .carousel-controls .next {
        margin-right: -10px;
      }
      .carousel-controls img {
        width: 30px;
        height: 30px;
      }
      .tick-text {
        font-size: 8px;
        font-weight: 400;
        line-height: 9.68px;
        text-align: left;
      }
      .tick-content img {
        width: 8.64px;
        height: 8.64px;
      }
      .carousel-wrapper {
        justify-content: center;
      }
      .carousel-item .pricing {
        font-size: 12px;
        font-weight: 700;
        line-height: 16.1px;
        text-align: left;
      }
      .pricing-container {
        min-height: 0px;
      }
      .carousel-item .cancellation {
        font-size: 12px;
        font-weight: 700;
        line-height: 12.1px;
        text-align: left;
        margin-top: 10px;
        min-height: 0px;
      }
      .carousel-item button {
        font-size: 10px;
        font-weight: 700;
        line-height: 12.1px;
        text-align: center;
        padding: 10px 29px;
        margin: 20px 0px 20px 0px;
        width: 100%;
      }
      .tick-content {
        padding: 6px 0;
      }
      .carousel-text {
        padding-bottom: 0;
      }
      .pricing-container {
        padding: 0px 20px;
      }
    }
    @media screen and (max-width: 850px) and (min-width: 600px) {
      .carousel-container {
        margin: 0 50px;
      }
      .carousel-wrapper {
        justify-content: flex-start;
      }
    }
  `;
  
  constructor() {
    super();
    this.items = [];
    this.activeIndex = 0;
  }

  private highlightF(text: string): string {
    return text.replace(/f/g, '<span class="highlight-f">f</span>');
  }

  render() {
    const isSmallScreen = window.innerWidth <= 800;
    const cardWidth = isSmallScreen ? 230 : 370;
    const gapWidth = isSmallScreen ? 100 : 36;

    // Check if the slider should be active
    const isSliderActive = this.items.length > 3 || isSmallScreen; // Slider always active on small screens

    return html`
      <div class="carousel-wrapper">
        <div
          class="carousel-container"
          style="transform: translateX(-${this.activeIndex * (cardWidth + gapWidth)}px);"
        >
          ${this.items.map((item) => html`
            <div class="carousel-item">
              <div class="carousel-text">
                <h2>${item.title}</h2>
                <div class="subscription-parent">
                  <span class="subscription-text">Subscription</span>
                </div>
                <p class="normal-text">
                  ${unsafeHTML(this.highlightF(item.description.replace(/\\n/g, '<br />')))}
                </p>
                ${this.renderTicks(item.ticks)}
              </div>
              <div class="pricing-container">
                <div class="pricing">${item.pricingText || ''}</div>
                <div class="cancellation">${item.cancellationText}</div>
                ${item.disable ? null : html`<button @click=${() => this.onChoosePlan(item)}>Choose This Plan</button>`}
              </div>
            </div>
          `)}
        </div>
      </div>

      <div class="carousel-controls">
        <button class="prev" @click=${this.prevSlide} ?disabled=${!isSliderActive || this.activeIndex === 0}>
          <img src="/images/icons/left-chevron.svg" alt="Previous" />
        </button>
        <button class="next" @click=${this.nextSlide} ?disabled=${!isSliderActive || this.activeIndex === this.items.length - 1}>
          <img src="/images/icons/right-chevron.svg" alt="Next" />
        </button>
      </div>

      <div class="dot-container">
        ${this.items.map(
          (_, index) =>
            html`<div
              class="dot ${this.activeIndex === index ? 'active' : ''}"
              @click=${() => this.setIndex(index)}
            ></div>`
        )}
      </div>
    `;
  }

  renderTicks(ticks?: string[]) {
    return html`
      <div class="tick-container">
        ${ticks?.map(
          (tick) => html`
            <div class="tick-item">
              <hr />
              <div class="tick-content">
                <img src="/images/icons/tick-icon.svg" alt="tick icon" />
                <span class="tick-text">${tick}</span>
              </div>
            </div>
          `
        )}
      </div>
    `;
  }

  prevSlide() {
    const isSmallScreen = window.innerWidth <= 800;
    if (this.items.length > 3 || isSmallScreen) {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    }
  }

  nextSlide() {
    const isSmallScreen = window.innerWidth <= 800;
    if (this.items.length > 3 || isSmallScreen) {
      if (this.activeIndex < this.items.length - 1) {
        this.activeIndex++;
      }
    }
  }

  setIndex(index: number) {
    const isSmallScreen = window.innerWidth <= 800;
    if (this.items.length > 3 || isSmallScreen) {
      this.activeIndex = index;
    }
  }

  private onChoosePlan(plan: any) {
    this.dispatchEvent(
      new CustomEvent(PLAN_CHOSEN_EVENT, {
        detail: plan,
        bubbles: true,
        composed: true,
      })
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: PricingCarousel;
  }
}
