import { MultipleReasons } from '@/util/core/MultipleReasons';
import { Howl } from 'howler';
import { waitUntil } from '@/util/core/waitUntil';

export module MusicTracks {
  export const Main = '/audio/track1.mp3';
}

export const DEFAULT_MUSIC_VOLUME = 0.5;
const REGULAR_MUTE_REASON = 'manually-muted';

export class MusicService {
  public readonly reasonsToMute = new MultipleReasons();

  /** In seconds */
  public fadeDuration = 0.5;

  private _currentMusic: { music: Howl; resolve: () => void }[] = [];

  private _volume = DEFAULT_MUSIC_VOLUME;

  get volume(): number {
    return this._volume;
  }

  set volume(value: number) {
    this._volume = value;
    this._currentMusic.forEach(({ music }) => {
      music.volume(value);
    });
  }

  constructor() {
    this.reasonsToMute.on({
      change: hasReasons => {
        if (hasReasons) {
          this._currentMusic.forEach(({ music }) => {
            music.fade(this._volume, 0, this.fadeDuration * 1000);
          });
        } else {
          this._currentMusic.forEach(({ music }) => {
            music.fade(0, this._volume, this.fadeDuration * 1000);
          });
        }
      },
    });

    // Pause the music when the tab is not visible
    // document.addEventListener('visibilitychange', () =>
    //   this.reasonsToMute.set('tab-not-visible', document.hidden)
    // );

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this._currentMusic.forEach(({ music }) => music.pause());
      } else {
        this._currentMusic.forEach(({ music }) => music.play());
      }
    });

    // document.addEventListener('pointerdown', () => {
    //   for (const { music } of this._currentMusic) {
    //     if (music.playing()) continue;
    //     console.log('🎶 Music wasn\'t playing, playing it now after click');
    //     music.play();
    //   }
    // });
  }

  setMuted(shouldMute: boolean) {
    if (this.isMuted === shouldMute) {
      return;
    }

    this.reasonsToMute.set(REGULAR_MUTE_REASON, shouldMute);
  }

  get isMuted() {
    return this.reasonsToMute.has(REGULAR_MUTE_REASON);
  }

  async playTrack(mp3Url: string): Promise<void> {
    this.stopAllTracks();

    if (this.reasonsToMute.hasAny()) {
      return Promise.resolve();
    }

    Howler.ctx?.resume();

    await waitUntil(() => Howler.ctx?.state === 'running');

    return this._playTrack(mp3Url);
  }

  private _playTrack(mp3Url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const backgroundMusic = new Howl({
        html5: false,
        src: [mp3Url],
        loop: true,
        volume: this._volume,
        onloaderror: (_, error) => {
          console.warn('🎵 load error', error);
          reject(error);
        },
        onplayerror: (_, error) => {
          console.warn('🎵 play error', error);
          backgroundMusic.stop();
          this._currentMusic = [];
          reject(error);
        },
      });

      this._currentMusic.push({ music: backgroundMusic, resolve });
      backgroundMusic.play();
    });
  }

  playOnlyOnce(mp3Url: string) {
    this.stopAllTracks();

    if (this.reasonsToMute.hasAny()) {
      return Promise.resolve();
    }

    return new Promise<void>(resolve => {
      const backgroundMusic = new Howl({
        html5: false,
        src: [mp3Url],
        loop: false,
        volume: this._volume,
        onend: () => {
          this._currentMusic = this._currentMusic.filter(m => m.music !== backgroundMusic);
          resolve();
        },
      });
      this._currentMusic.push({ music: backgroundMusic, resolve });
      backgroundMusic.play();
    });
  }

  stopAllTracks(): void {
    this._currentMusic.forEach(({ music, resolve }) => {
      music.stop();
      resolve();
    });
    this._currentMusic = [];
  }
}
