import { LitElement, css, html } from 'lit-element';
import { customElement, property } from 'lit/decorators.js';

import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { SkinColors, skinColorsToDisplayNames } from './SkinColors';

const TAG_NAME = 'skin-pick-button';
declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: SkinPickButton;
  }
}

@customElement(TAG_NAME)
export class SkinPickButton extends LitElement {
  static readonly styles = css`
    :host {
      --size: 6vmin;

      display: inline-block;
      position: relative;
      width: var(--size);
      height: var(--size);

      overflow: visible;
      pointer-events: all;
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500%;
      height: auto;
      transform: translate(-50%, -50%);
      pointer-events: none;

      animation: appear 0.55s ease-in-out;

      transition-property: width, opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }

    .overlay {
      opacity: 0;
      transition-duration: 0.6s;
    }

    :host(:hover) .overlay,
    :host([selected]) .overlay {
      opacity: 1;
    }

    :host(:hover) img {
      transition-duration: 0.2s;
      width: 520%;
    }

    :host([selected]) img {
      width: 640%;
    }

    label {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      padding-top: 2vmin;
      font-size: 2.5vmin;

      text-shadow:
        0 0 2px black,
        0 0 8px black;
    }

    @keyframes appear {
      from {
        transform: translate(-50%, -50%) rotate(180deg) scale(0);
      }
      to {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
      }
    }
  `;

  @property({ type: SkinColors }) color = '' as SkinColors;
  @property({ type: Boolean, reflect: true }) selected = false;

  public readonly onClick = createDelegateFunction();

  render() {
    const colorFilePrefix = this.color.toLowerCase();
    const colorDisplayName = skinColorsToDisplayNames[this.color];
    return html`
      <img src="images/skin-splash/${colorFilePrefix}-off.webp" alt="" />
      <img class="overlay" src="images/skin-splash/${colorFilePrefix}-on.webp" alt="" />
      <label>${colorDisplayName}</label>
    `;
  }

  firstUpdated() {
    this.addEventListener('click', this.onClick);
  }
}
