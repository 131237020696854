import gsap from 'gsap';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { trackOrientationAsCSSClass } from '@/helpers/trackOrientationAsCSSClass';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';
import { ReusableStyles } from './common/styles';

import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';

import './common/BlurryWrapper';
import './common/SmallSpinner';
import { getShifterName } from '@/util/getShifterName';

const TAG_NAME = 'already-signed-in-screen';

@customElement(TAG_NAME)
export class AlreadySignedInScreen extends LitElement {
  static readonly tagName = TAG_NAME;

  static readonly styles = css`
    :host {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      zoom: 0.75;
    }

    :host(.portrait) {
      zoom: 0.55;
    }

    .container {
      width: 90%;
      max-width: 1280px;
      margin: auto;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2vmin;
      box-sizing: border-box;
      position: relative;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 480px;
      position: relative;
    }

    .title {
      font-size: 60px;
      margin-bottom: 20px;
      color: #fff;
      text-align: center;
      font-family: Lora, serif;
      font-style: normal;
      font-weight: 400;
      line-height: 64px;
      letter-spacing: 0.6px;
    }

    .subtitle {
      color: #fff;
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.16px;
      margin-top: 1em;
      margin-bottom: 2em;
    }

    ${ReusableStyles.glassButton}

    button.glass-button {
      max-width: 80%;
      width: 50vmin;
    }
  `;

  @property({ type: String }) shifterName = '';
  @property({ type: Boolean }) loading = false;

  public readonly onContinueClick = createDelegateFunction();

  firstUpdated(): void {
    trackOrientationAsCSSClass(this);
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          <div class="title">Welcome back, ${this.shifterName}</div>
          <div class="subtitle">You're already signed in</div>

          <button @click=${this.handleContinueClick} class="glass-button" ?disabled=${this.loading}>
            ${this.loading ? html`<small-spinner size="1em"></small-spinner>` : html`Continue to Presence Shift`}
          </button>
        </div>
      </div>
    `;
  }

  handleContinueClick() {
    this.loading = true;
    this.onContinueClick.emit();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: AlreadySignedInScreen;
  }
}

export async function displayAlreadySignedInScreen(parentElement: HTMLDivElement) {
  const awaitable = createCustomAwaitable<void>();

  const alreadySignedInScreen = new AlreadySignedInScreen();
  alreadySignedInScreen.shifterName = getShifterName();
  alreadySignedInScreen.onContinueClick.on(async () => {
    alreadySignedInScreen.loading = true;
    awaitable.resolve();
  });

  parentElement.appendChild(alreadySignedInScreen);

  // Fade in
  await gsap.from(alreadySignedInScreen, { duration: 0.5, opacity: 0 });

  // Wait for user interaction
  await awaitable;

  // Fade out
  await gsap.to(alreadySignedInScreen, { duration: 0.5, opacity: 0 });

  // Remove from DOM
  parentElement.removeChild(alreadySignedInScreen);
}
