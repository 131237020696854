import { LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('fastforward-hit-area')
export class FastforwardHitArea extends LitElement {
  static styles = css`
    :host {
      --le-margin: 4vmin;

      box-sizing: border-box;
      position: absolute;

      top: var(--le-margin);
      right: var(--le-margin);
      bottom: var(--le-margin);
      left: 50%;

      pointer-events: all;
    }

    :host(.disabled) {
      pointer-events: none;
      display: none;
    }

    //// DEBUG ////

    :host(.debug) {
      outline: 2px dashed #0ff6;
    }
    :host(.debug) .inner {
      outline: 1px dotted #ff0;
    }
  `;
}
