import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('circular-progress-bar')
export class CircularProgressBar extends LitElement {
  static styles = css`
    :host {
      display: block;
      --progress-bar-color: #fff4;
    }
    svg {
      transform: rotate(-90deg);
    }
    .progress-ring__circle {
      stroke: var(--progress-bar-color);
      fill: transparent;
      transition: 0.35s stroke-dashoffset;
      stroke-linecap: round;
    }
  `;

  @property({ type: Number })
  progress = 0;

  @property({ type: Number })
  radius = 50;

  @property({ type: Number })
  stroke = 4;

  render() {
    const normalizedRadius = this.radius - this.stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - this.progress * circumference;

    return html`
      <svg height="${this.radius * 2}" width="${this.radius * 2}">
        <circle
          stroke-width="${this.stroke}"
          fill="transparent"
          r="${normalizedRadius}"
          cx="${this.radius}"
          cy="${this.radius}"
          class="progress-ring__circle"
          style="stroke-dasharray: ${circumference} ${circumference}; stroke-dashoffset: ${strokeDashoffset}"
        ></circle>
      </svg>
    `;
  }
}
