import gsap from 'gsap';

import { LitElement, css, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import Showdown from 'showdown';

import { createCustomAwaitable } from '@/util/core/createCustomAwaitable';
import { createDelegateFunction } from '@/util/core/createDelegateFunction';

const TAG_NAME = 'placeholder-splash-screen';

const mdConverter = new Showdown.Converter();

class PlaceholderSplashScreen extends LitElement {
  public static readonly styles = css`
    :host {
      --splash-screen-padding: 0vmin;

      position: absolute;
      top: var(--splash-screen-padding);
      left: var(--splash-screen-padding);
      right: var(--splash-screen-padding);
      bottom: var(--splash-screen-padding);

      display: flex;
      justify-content: center;
      align-items: center;

      font-family: 'Helvetica Neue', sans-serif;

      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(10px);
    }

    .container {
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 3em;
      text-align: center;
      color: white;

      max-height: 80%;
      max-width: 90vmin;
    }

    .container .content {
      overflow-y: auto;
      text-align: start;
      max-width: 100%;
      padding: 1em;
    }

    .container .content h1,
    .container .content h2,
    .container .content h3,
    .container .content h4,
    .container .content h5,
    .container .content h6 {
      align-items: center;
    }

    .container .content small {
      display: block;
      font-size: 0.7em;
      opacity: 0.7;
    }

    .container .content a {
      pointer-events: all;
      cursor: pointer;
      font-weight: bold;
      color: #bef;
      text-shadow: 0 0 0px #9df;
      transition:
        color 0.2s,
        text-shadow 0.2s;
    }

    .container .content a:hover {
      color: #fff;
      text-shadow: 0 0 10px #3cf;
    }

    .container .continue-button {
      font-size: 1.25em;
      margin-top: 3rem;
      padding: 0.75rem 1.5rem;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;
      border: none;
      border-radius: 25px;
      cursor: pointer;
      transition:
        color 0.2s,
        padding 0.2s;

      font-family: Inter;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: 3rem;
    }

    .container .continue-button:hover {
      color: dodgerblue;
      padding: 0.75rem 2.5rem;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  `;

  public readonly onContinue = createDelegateFunction();
  public readonly onHyperlink = createDelegateFunction<[text: string]>();

  public hideContinueButton = false;
  public textContent = '';

  render() {
    const htmlTextContent = mdConverter.makeHtml(this.textContent);
    return html`
      <div class="container">
        <div class="content">${unsafeHTML(htmlTextContent)}</div>
        ${this.hideContinueButton
          ? ''
          : html` <button class="continue-button" @click=${this.onContinue}>Continue</button> `}
      </div>
    `;
  }

  firstUpdated() {
    const links = this.shadowRoot?.querySelectorAll('.content a');
    links?.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
        const element = event.target as HTMLAnchorElement | null;
        if (!element) return;
        this.onHyperlink(element.innerText);
      });
    });
  }
}

customElements.define(TAG_NAME, PlaceholderSplashScreen);

export async function displayPlaceholderSplash(
  parentElement: HTMLDivElement,
  content?: string,
  hideContinueButton = false
) {
  const awaitable = createCustomAwaitable<string | void>();

  const splashScreen = new PlaceholderSplashScreen();
  splashScreen.onContinue.on(async () => awaitable.resolve());
  splashScreen.onHyperlink.on(async (text: string) => awaitable.resolve(text));
  splashScreen.textContent = content ? content.trim() : '<h1><i>// Placeholder Screen //<i></h1>';
  splashScreen.hideContinueButton = hideContinueButton;
  parentElement.appendChild(splashScreen);

  await gsap.from(splashScreen, { duration: 0.5, opacity: 0 });

  const result = await awaitable;

  await gsap.to(splashScreen, { duration: 0.5, opacity: 0 });

  parentElement.removeChild(splashScreen);

  return result;
}
