import { buildVars } from '@/constants/buildVars';
import { appContext } from '@/context/appContext';

export function getOneSpecificCinematicSlugToPlay() {
  const key = getSlugFromUrl();

  if (key === null) {
    return null;
  }

  return buildVars.cinematics[key] || key;
}

function getSlugFromUrl() {
  // Check for 'play' parameter in URL query
  const searchKey = appContext.urlParams.play;

  // Check for '#play/<slug>' in URL hash, allowing for additional segments
  const hashMatch = window.location.hash.match(/#\/?play\/([^\/]+)/);
  const hashKey = hashMatch ? hashMatch[1] : null;

  // Check for '/play/<slug>' in pathname
  const pathnameMatch = window.location.pathname.match(/\/play\/([^\/]+)/);
  const pathnameKey = pathnameMatch ? pathnameMatch[1] : null;

  // Use searchKey from URL params if available, otherwise use hashKey, then pathnameKey
  const key = (searchKey || hashKey || pathnameKey)?.replace(/\/+/g, '');

  console.log({ searchKey, hashKey, pathnameKey, key });

  if (key) {
    return key.replace(/\/+/g, '');
  }

  return null;
}
