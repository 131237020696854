import { appContext } from '@/context/appContext';
import { LitElement, css, html } from 'lit';

const TAG_NAME = 'shifter-interactive-zone';

export function addShifterInteractiveZone(parentElement: Element) {
  const touchZone = document.createElement(TAG_NAME);
  parentElement.appendChild(touchZone);

  if (appContext.urlParams.debug) {
    touchZone.classList.add('debug');
  }

  return touchZone;
}

class ShifterInteractiveZone extends LitElement {
  static styles = css`
    :host {
      --le-margin: 5px;

      box-sizing: border-box;
      position: absolute;

      top: var(--le-margin);
      right: var(--le-margin);
      bottom: var(--le-margin);
      left: var(--le-margin);
      // left: 50%;

      pointer-events: all;
    }

    :host(.debug) {
      outline: 2px dashed #0f06;
      background: url('https://upload.wikimedia.org/wikipedia/commons/e/e3/Meander%2C_Greek_Key.png');
      background-size: 128px;
      opacity: 0.2;
    }
  `;
}

customElements.define(TAG_NAME, ShifterInteractiveZone);
