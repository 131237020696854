type OrientationListener = (info: {
  orientation: 'landscape' | 'portrait';
  ratio: number;
  isLandscape: boolean;
  isPortrait: boolean;
}) => void;

export function createOrientationTracker(elementToTrack: HTMLElement = window.document.body) {
  const listeners: OrientationListener[] = [];

  const info = {
    orientation: 'landscape' as 'landscape' | 'portrait',
    ratio: 1,
    isLandscape: true,
    isPortrait: false,
  };

  const resizeObserver = new ResizeObserver(entries => {
    const entry = entries[0];
    if (!entry) throw new Error('No entry found');

    // Get the element's current width and height
    const width = entry.contentRect.width;
    const height = entry.contentRect.height;

    // Determine whether the element is in landscape or portrait mode
    const orientation = width > height ? 'landscape' : 'portrait';

    // Calculate the ratio of width to height
    const ratio = width / height;

    // Update the info object with the new orientation and ratio
    info.orientation = orientation;
    info.ratio = ratio;
    info.isLandscape = orientation === 'landscape';
    info.isPortrait = orientation === 'portrait';

    // Call all listener functions with the orientation and ratio
    for (let listener of listeners) {
      listener(info);
    }
  });

  resizeObserver.observe(elementToTrack);

  return {
    getOrientation() {
      return info.orientation;
    },
    getRatio() {
      return info.ratio;
    },
    getIsLandscape() {
      return info.isLandscape;
    },
    getIsPortrait() {
      return info.isPortrait;
    },
    addListener: (listener: OrientationListener, triggerNow = false) => {
      listeners.push(listener);
      if (triggerNow) {
        listener(info);
      }
    },
    removeListener: (listener: OrientationListener) => {
      const index = listeners.indexOf(listener);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    },
  };
}
