import gsap from 'gsap';

import { Mesh, MeshStandardMaterial } from 'three';
import { GLTF } from 'three/examples/jsm/Addons.js';

import { createCharacterAnimationController } from '@/controllers/createCharacterAnimationController';
import { createBubbleParticlesCtrl } from '@/scene/createBubbleParticlesCtrl';
import { lerp } from 'three/src/math/MathUtils.js';
import { delay } from '@/util/core/delay';

export function createHomeSceneControls(home: GLTF, addEnterFrameCallback: (callback: () => void) => void) {
  const props = {
    maxFakeLightsIntensity: 0.0,
  };

  type MeshWithMaterial = Mesh & { material: MeshStandardMaterial };
  const fakeLights = [
    home.scene.getObjectByName('FakeLight1') as undefined | MeshWithMaterial,
    home.scene.getObjectByName('FakeLight2') as undefined | MeshWithMaterial,
  ] as const;

  fakeLights.forEach((light, i) => {
    if (!light) return;
    light.onBeforeRender = () => {
      const time = performance.now() * 0.0015;
      const sine = i == 0 ? Math.sin(time) : Math.cos(time + 1);
      light.material.opacity = (props.maxFakeLightsIntensity + props.maxFakeLightsIntensity * sine) * 0.5;
    };
  });

  const clipCtrl = createCharacterAnimationController(home, addEnterFrameCallback);

  const bubblesCtrl = createBubbleParticlesCtrl(home.scene!);

  //// TIME FOR THE BUBBLES

  bubblesCtrl.addStaticBubbles(30);
  bubblesCtrl.addDynamicBubbles(150);

  async function startRandomBubbleSpraysLoop() {
    while (true) {
      const randomDelay = lerp(0, 4, Math.random());
      await delay(randomDelay);

      const randomSpawnPoint = {
        x: lerp(-750, 750, Math.random()),
        y: 0,
        z: lerp(-2000, 0, Math.random()),
      };
      bubblesCtrl.sprayBubbles(randomSpawnPoint, 4, 4, 0.4);
    }
  }
  startRandomBubbleSpraysLoop();

  const ctrl = {
    clipCtrl,
    bubblesCtrl,
    maxFakeLightsIntensityDefault: 0.6,
    fadeFakeLightsIntensityTo: function (value: number, duration: number = 0.5, delayBefore = 0) {
      gsap.to(props, {
        maxFakeLightsIntensity: value,
        duration: duration,
        delay: delayBefore,
        ease: 'power2.inOut',
      });
    },
  };

  return ctrl;
}
