import { createDelegateFunction } from './createDelegateFunction';

enum ModifierType {
  MULTIPLIER = 'mul',
  ADDEND = 'add',
}

type Modifier = {
  type: ModifierType;
  value: number;
  set(newValue: number): void;
};

export class SmartNumber {
  private result: number;
  private modifiers: Readonly<Modifier>[];

  public readonly onChange = createDelegateFunction<[newValue: number, oldValue: number]>();

  constructor(private baseValue: number = 1) {
    this.result = baseValue;
    this.modifiers = [];
  }

  addMultiplier(value: number) {
    const modifier = {
      type: ModifierType.MULTIPLIER,
      value,
      set: (newValue: number) => {
        modifier.value = newValue;
        this.recalculateAndCallOnChangeIfChanged();
      },
      remove: () => {
        this.removeModifier(modifier);
      },
    };

    this.modifiers.push(modifier);

    this.recalculateAndCallOnChangeIfChanged();

    return modifier;
  }

  addAddend(value: number) {
    const modifier = {
      type: ModifierType.ADDEND,
      value,
      set: (newValue: number) => {
        modifier.value = newValue;
        this.recalculateAndCallOnChangeIfChanged();
      },
      remove: () => {
        this.removeModifier(modifier);
      },
    };

    this.modifiers.push(modifier);

    this.recalculateAndCallOnChangeIfChanged();

    return modifier;
  }

  removeModifier(modifier: Modifier): void {
    const index = this.modifiers.indexOf(modifier);
    if (index !== -1) {
      this.modifiers.splice(index, 1);
    } else {
      throw new Error(`Modifier not found: ${JSON.stringify(modifier)}`);
    }

    this.recalculateAndCallOnChangeIfChanged();
  }

  private recalculateAndCallOnChangeIfChanged(): void {
    const oldValue = this.result;
    this.recalculate();
    const newValue = this.result;

    if (oldValue !== newValue) {
      this.onChange.emit(newValue, oldValue);
    }
  }

  private recalculate(): void {
    this.result = this.baseValue;
    for (const { type, value } of this.modifiers) {
      if (type === ModifierType.MULTIPLIER) {
        this.result *= value;
      } else if (type === ModifierType.ADDEND) {
        this.result += value;
      }
    }
  }

  get(forceRecalculate = false): number {
    if (forceRecalculate || this.result === undefined) {
      this.recalculate();
    }

    return this.result;
  }
}
