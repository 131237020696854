import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { appContext } from '@/context/appContext';
import { auth } from './firebase';

// Sign up with email and password
export async function signUpWithEmail(email: string, password: string) {
  // let previousUser = auth.currentUser || null;

  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;

  console.log('🔑 [Email+Pass] (new):', user);

  appContext.userData.authUserId = user.uid;
}

// Sign in with email and password
export async function signInWithEmail(email: string, password: string) {
  // let previousUser = auth.currentUser || null;

  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  const user = userCredential.user;

  console.log('🔑 [Email+Pass]:', user);

  appContext.userData.authUserId = user.uid;
}

// Send password reset email
export async function sendPasswordReset(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent to:', email);
  } catch (error) {
    console.error('🔑 Error sending password reset email:', error);
  }
}

// Link email and password to an existing user
async function linkEmailAndPasswordToUser(email: string, password: string) {
  const user = auth.currentUser;

  if (user) {
    // Create an email/password credential
    const credential = EmailAuthProvider.credential(email, password);

    try {
      // Link the credential to the existing user
      await linkWithCredential(user, credential);
      console.log('Email and password linked to the existing user successfully.');
    } catch (error) {
      console.error('Error linking email and password to user:', error);
    }
  } else {
    console.log('No user is currently signed in.');
  }
}
