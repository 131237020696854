export enum SkinColors {
  Default = 'presence',
  Pink = 'crimson',
  Green = 'emerald',
  DeepBlue = 'sapphire',
}

export const skinColorsToDisplayNames: Record<SkinColors, string> = {
  [SkinColors.Default]: 'Ocean',
  [SkinColors.Pink]: 'Pink',
  [SkinColors.Green]: 'Emerald',
  [SkinColors.DeepBlue]: 'Electric',
};
