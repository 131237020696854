import { SocialSharePlatform } from '@/services/createSocialSharingService';

export module ConversionReporting {
  export type TrackableThing =
    | `share_on_${SocialSharePlatform}`
    | 'pay_ps1'
    | 'pay_beta'
    | 'sign_up'
    | 'sign_in'
    | 'pass_landing_screen'
    | 'pass_begin_screen'
    | 'pass_intro_video';

  export const trackableIds: Record<TrackableThing, string | null> = {
    share_on_facebook: 'T_FgCLy73cAZELPtkL0o',
    share_on_xwitter: 'oWZcCNqPtMEZELPtkL0o',
    share_on_email: 'kg9ECOyTrcEZELPtkL0o',
    share_on_native_share: '2UfuCJfey8EZELPtkL0o',
    share_on_instagram: null,
    pay_ps1: 'EfqOCL-crcEZELPtkL0o',
    pay_beta: null,
    sign_up: 'sqKACIexqsEZELPtkL0o',
    sign_in: null,
    pass_landing_screen: null,
    pass_intro_video: null,
    pass_begin_screen: null,
  };

  export function reportConversion(type: TrackableThing, url?: Location) {
    const send_to_suffix = trackableIds[type];

    if (!send_to_suffix === null || send_to_suffix === undefined) {
      return;
    }

    var callback = function () {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };

    gtag('event', 'conversion', {
      send_to: 'AW-10865620659/' + send_to_suffix,
      value: 1.0,
      currency: 'USD',
      event_callback: callback,
    });

    fbq('trackCustom', 'Conversion', { type: type });

    return false;
  }
}
