import { gsap } from 'gsap';

import { appContext } from '@/context/appContext';

export const timeScale = appContext.urlParams.timeScale ?? 1;

export const delay = (seconds: number) => {
  if (seconds <= 0) return Promise.resolve();
  return new Promise(resolve => gsap.delayedCall(seconds / timeScale, resolve));
};
