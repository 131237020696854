import { LitElement, html, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';

const TAG_NAME = 'modern-input';

@customElement(TAG_NAME)
export class ModernInput extends LitElement {
  static readonly tagName = TAG_NAME;

  @property({ type: String }) label: string;
  @property({ type: String }) name: string;
  @property({ type: String }) placeholder: string;
  @property({ type: String }) type: string;
  @property({ type: String }) value: string;

  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      pointer-events: all;
      width: 100%;
    }

    .input-label {
      font-family: Inter;
      font-size: 16px;
      font-weight: bold;
      font-weight: 700;
      font-style: normal;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      color: #ffffff;
      margin-bottom: 5px;
      text-align: left;
    }

    .input-field {
      background: transparent;
      border: none;
      border-bottom: 2px solid #fff6;
      padding: 10px;
      font-size: 16px;
      color: #ffffff;
      outline: none;
      transition: border-color 0.3s;
    }

    .input-field:focus {
      border-bottom: 2px solid #00ff99;
    }

    .input-field::placeholder {
      text-align: center;
      color: #ffffff;
      opacity: 0.4;
    }
  `;

  constructor() {
    super();

    this.label = '';
    this.name = '';
    this.placeholder = '';
    this.type = 'text';
    this.value = '';
  }

  handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this.value = target.value;

    const inputEvent = new CustomEvent('input-change', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });

    this.dispatchEvent(inputEvent);
  }

  render() {
    return html`
      <label class="input-label">${this.label}</label>
      <input
        id="modern-input"
        class="input-field"
        type=${this.type}
        placeholder=${this.placeholder}
        .value=${this.value}
        @input=${this.handleInput}
      />
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    [TAG_NAME]: ModernInput;
  }
}
