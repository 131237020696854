export function capitalizeFirstLetter(str: string): string {
  try {
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(/[a-zA-Z]/)) {
        return str.slice(0, i) + str[i].toUpperCase() + str.slice(i + 1);
      }
    }
    return str;
  } catch (error) {
    console.error('🐬 Error capitalizing string:', str, error);
    return str;
  }
}
