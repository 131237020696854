import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('social-icon')
export class SocialIcon extends LitElement {
  static styles = css`
    :host {
      position: relative;
      display: inline-block;
      width: 64px;
      height: 64px;
      background: url('/images/small-share-btn.png') no-repeat center center;

      pointer-events: all;
      cursor: pointer;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      fill: var(--icon-color, #00ffc2);
      width: 64px;
    }
  `;

  @property({ type: String })
  public readonly icon = 'facebook';

  render() {
    const imgUrl = IMG_URLS[this.icon];
    return html` <img id="social-icon-${this.icon}" src="${imgUrl}" alt="${this.icon}" /> `;
  }
}

const IMG_URLS = {
  xwitter: '/images/icons/ico-xwitter.svg',
  facebook: '/images/icons/ico-facebook.svg',
  instagram: '/images/icons/ico-instagram.svg',
  email: '/images/icons/ico-email.svg',
  native_share: '/images/icons/ico-share.svg',
};
