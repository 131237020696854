import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';

export class SmallSpinner extends LitElement {
  static styles = css`
    .spinner {
      width: 50px;
      height: 50px;
      border: 5px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      border-top-color: cyan;
      animation: spin 1s infinite linear;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  `;

  @property({ type: String }) size = '50px';

  render() {
    return html`<div class="spinner" style="width: ${this.size}; height: ${this.size};"></div>`;
  }
}

customElements.define('small-spinner', SmallSpinner);
