const all = {
  IdleCycle: 'Idle Cycle',
  LaughCycle: 'Laugh Cycle',

  Loop2: 'Loop 2',
  Loop2Ver2: 'Loop 2 ver.2',
  Loop1Ready: 'Loop 1 (ready)',
  Waiting: 'Waiting',
  WaitingForExport: 'Waiting (for export)',
  Nodding: 'Nodding',
  Talking: 'Talking',

  PresenceModeEnter: 'Presence Mode Enter',
  PresenceModeCycle: 'Presence Mode Cycle',
  EncounterPenguin: 'Encounter Penguin',
  EncounterPenguin2: 'Encounter Penguin 2B',
  PresenceReturns: 'Presence Returns',
  Exit1: 'Exit 1',
  Sink: 'Sink',
  Sink1: 'Sink 1',
  Sink2: 'Sink 2',
  SwimOnBack: 'Swim On Back',
  EmergeAndPoint: 'Emerge and Point',
  PresenceEmerges: 'Presence Emerges (direction chang) for export ',
  PenguineEncounterRootAdjustedMergedExporting: 'PenguineEncounter_root adjusted_merged_exporting',
  Breathing2Export: 'Breathing2_Export',
  PresenceIntro: 'Presence_Intro',
};

export const animationBook = {
  cycles: {
    //// D1 ////
    idle: all.IdleCycle,
    loop1: all.Loop1Ready,
    loop2a: all.Loop2,
    loop2b: all.Loop2Ver2,
    waiting: all.WaitingForExport,
    nodding: all.Nodding,
    talking: all.Talking,
    laughing: all.LaughCycle,
    presenceMode: all.PresenceModeCycle,

    //// D2 ////
    dolphiniaIdle: 'Dolphinia Idle',
  },
  emotes: {
    //// D1 ////
    laugh: all.LaughCycle,
    nod: all.Nodding,
    talk: all.Talking,
    swimOnBack: all.SwimOnBack,
    enterPresenceMode: all.PresenceModeEnter,
    encounterPenguin: all.EncounterPenguin,
    encounterPenguin2: all.EncounterPenguin2,
    // encounterPenguin: all.PenguineEncounterRootAdjustedMergedExporting,
    enterSceneVer1: all.PresenceIntro,
    enterSceneVer2: all.PresenceReturns,
    exitSceneVer1: all.Exit1,
    exitSceneVer2: all.PresenceEmerges,
    // emerge: all.EmergeAndPoint,
    swimUp: 'Swim Up',
  },
  getAll() {
    return all;
  },
};

export const animationBook2 = {
  DolphiniaSummersaultShort: 'A32|Dolphinia Summersault Short',
  DolphiniaIdle: 'AX1-IDLE',
  DolphiniaExit2: 'AX1-EXIT2',
  DolphiniaSummon: 'AX1-SUMMON',
  DolphiniaSwim: 'AX1-SWIM',
  Nodding: 'A21_Nodding',
  Waiting: 'A31_Waiting',
  //
  DolphiniaExit: 'A34|Dolphinia Exit',
  WaitingForExport: 'A31|Waiting (for export)',
  Loop1Ready: 'A25|Loop 1 (ready)',
  PresenceIntro003ForExport: 'A29|Presence_intro_003_for export',
  IdleCycle: 'A07|Idle Cycle',
  LaughCycle: 'A08|Laugh Cycle',
  Loop1: 'A09|Loop 1',
  Loop2Ver2: 'A11|Loop 2 ver.2',
  PresenceModeEnter: 'A12|Presence Mode Enter',
  PresenceModeCycle: 'A13|Presence Mode Cycle',
  PresenceReturns: 'A15|Presence Returns',
  Exit1: 'A16|Exit 1',
  Sink: 'A17|Sink',
  Sink2: 'A19|Sink 2',
  SwimOnBack: 'A20|Swim On Back',
  Talking: 'A22|Talking',
  EmergeAndPoint: 'A24|Emerge and Point',
};
