import { createDelegateFunction } from '@/util/core/createDelegateFunction';

/**
 * UserAnswersStore is a class that manages the storage of user answers.
 * It provides methods to get, set, and retrieve all user answers.
 */
export class UserAnswersStore {
  // Object to store the user answers
  private readonly values = {} as Record<string, string | null>;

  // Delegate function that is called when a value changes
  public readonly onValueChange = createDelegateFunction<[key: string, value: string | null]>();

  /**
   * Retrieves the value for a given key from the store.
   * If the value is invalid (empty, null, or undefined), it returns the default value.
   *
   * @param key - The key of the value to retrieve.
   * @returns The value associated with the key, or the default value if the value is invalid.
   */
  getValue(key: string) {
    const storedValue = this.values[key];

    const invalidValues = ['', null, undefined];
    if (invalidValues.includes(storedValue)) {
      return null;
    }

    return storedValue!;
  }

  /**
   * Sets the value for a given key in the store.
   * If the value is an empty string, it sets the value to null.
   *
   * @param key - The key of the value to set.
   * @param value - The value to set.
   */
  setValue(key: string, value: string | null) {
    this.values[key] = value;

    this.onValueChange.emit(key, value);
  }

  /**
   * Retrieves all values from the store.
   *
   * @returns An object containing all stored values.
   */
  getAllValues() {
    const values = { ...this.values };

    return values;
  }
}
