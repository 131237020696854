export function createCustomAwaitable<TReturn = void>() {
  let resolveFn: (value: TReturn) => void;
  let rejectFn: (reason?: any) => void;
  let isSettled = false;

  const promise = new Promise<TReturn>((resolve, reject) => {
    resolveFn = (value: TReturn) => {
      isSettled = true;
      resolve(value);
    };
    rejectFn = (reason?: any) => {
      isSettled = true;
      reject(reason);
    };
  });

  const customAwaitable = {
    then: (onFulfilled: (value: TReturn) => void, onRejected?: (reason: any) => void) => {
      promise.then(onFulfilled, onRejected);
      return customAwaitable;
    },
    catch: (onRejected: (reason: any) => void) => {
      promise.catch(onRejected);
      return customAwaitable;
    },
    finally: (onFinally: () => void) => {
      promise.finally(onFinally);
      return customAwaitable;
    },
    resolve: (value: TReturn) => {
      resolveFn(value);
    },
    reject: (reason?: any) => {
      rejectFn(reason);
    },
    isSettled: () => isSettled,
  };

  return customAwaitable;
}
