import gsap from 'gsap';

import { appContext } from '@/context/appContext';

import { buildVars } from '@/constants/buildVars';
import { defaultVars } from '@/misc/defaultVars';
import { createFlatStringyVarsContext, createStringyVarsContext } from '@/services/createStringyVarsContext';
import { updateWatchedVar } from './debug-box';

export async function initializeDevThingsForApp() {
  Object.assign(globalThis, {
    appContext,
    defaultVars,
    buildVars,
    createFlatStringyVarsContext,
    createStringyVarsContext,
    updateWatchedVar,
    gsap,
    IMPORT_META_ENV: import.meta.env,
    Howeler: Howler,
    Howl: Howl,
  });

  //// Add "debug" class to app div if debug mode specified in URL
  if (appContext.urlParams.debug) {
    appContext.appDiv.classList.add('debug');
  }
}
